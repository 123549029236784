import { apiHost } from '../constants/ApiConstants';

export default class ImageUtils {
    static getImageUrlFromFile(imageFile) {
        if (!imageFile)
          return null;
    
        return URL.createObjectURL(imageFile);
    }

    static getImageUrlFromUri(uri) {
        if (!uri)
            return null;

        return new URL(apiHost + "/" + uri)
    }
}
