import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import DoneIcon from '@material-ui/icons/Done';
import MapIcon from '@material-ui/icons/Map';

import ApiTour from '../../API/ApiTour';
import ApiUser from '../../API/ApiUser';
import DraggableList from "../components/draggableList";
import ListItemLink from '../components/listItemLink';
import DialogWarning from '../components/dialogWarning';
import Authenticated from '../components/authenticated';
import AuthStorage from "../../utils/authStorage";
import MainAppBar from "../components/MainAppBar";
import { apiHost } from '../../constants/ApiConstants';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    margin: theme.spacing(5),
    borderRadius: 5,
    listStyle: 'none'
  }
});

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      toursOrderIdChanged: false,
      toursLoaded: false,
      dialogWaringOpened: false,
      tourIdToDelete: -1,
      tours: [
        {
          name: "",
          createdAt: "",
          isValid: false,
          orderId: 0,
          id: 0
        }
      ]
    }

    this.onDragEnd = this.onDragEnd.bind(this);
    this.deleteTour = this.deleteTour.bind(this);
    this.openDeleteTourDialog = this.openDeleteTourDialog.bind(this);
    this.closeDeleteTourDialog = this.closeDeleteTourDialog.bind(this);
    this.duplicateTour = this.duplicateTour.bind(this);
  }

  componentDidMount() {
    document.title = "Parcours | Landing Zone"

    this.setStateIsAdmin()
    this.getTours();
  }

  async duplicateTour(tourId) {
    let res = await ApiTour.duplicateTour(tourId)

    if (res.status == 201) {
      this.getTours();

      console.log("res: ", res)

      this.setState(prevState => ({
        ...prevState,
        showSuccess: true,
        showError: false,
        successMessage: "Le circuit à bien été dupliqué",
        dialogWaringOpened: false
      }));
    } else if (res.status == 403) {
      this.setState(prevState => ({
        ...prevState,
        showSuccess: false,
        showError: true,
        errorMessage: "Vous avez atteint le nombre maximum de parcours autorisé.",
        dialogWaringOpened: false
      }));
    } else {
      console.log("Res: ", res)
      this.setState(prevState => ({
        ...prevState,
        showSuccess: false,
        showError: true,
        errorMessage: "Une érreur est survenue",
        dialogWaringOpened: false
      }));
    }
  }

  async getTours() {
    ApiTour.getTours().then((tours) => {

      var dateOptions = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "numeric"
      };

      let toursDatas = [];

      if (!tours.data)
        return

      tours.data.forEach((elem, i) => {
        toursDatas.push({
          name: elem.title,
          createdAt: new Date(elem.createdAt).toLocaleDateString("fr", dateOptions),
          id: elem.id,
          isValid: elem.isValid
        });

      });


      this.setState(prevState => ({
        ...prevState,
        toursLoaded: true,
        tours: toursDatas
      }));

    });
  }

  onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const toursDatas = this.state.tours;

    const [tourRemoved] = toursDatas.splice(source.index, 1);
    toursDatas.splice(destination.index, 0, tourRemoved);

    toursDatas.forEach((tour, id) => {
      tour.orderId = id;
    });

    this.setState(prevState => ({
      ...prevState,
      toursOrderIdChanged: true,
      toursLoaded: true,
      toursDatas: toursDatas
    }), async () => {
      let res = await ApiTour.updateToursOrdersIds(this.state.tours);
    });

  };

  async deleteTour(tourId) {
    let res = await ApiTour.deleteTour(tourId);

    if (res.status == 200) {
      let newTours = this.state.tours;
      newTours.forEach( (tour, index) => {
        if (tour.id == tourId) {
          newTours.splice(index, 1);
        }
      });
      this.setState(prevState => ({
        ...prevState,
        showSuccess: true,
        successMessage: "Le circuit à bien été supprimé",
        dialogWaringOpened: false,
        tours: newTours
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        showError: true,
        errorMessage: "Une érreur est survenue durant la suppréssion du circuit",
        showSuccess: false,
        dialogWaringOpened: false,
      }));
    }
  }

  openDeleteTourDialog(tourId) {
    this.setState(prevState => ({
      ...prevState,
      tourIdToDelete: tourId,
      dialogWaringOpened: true
    }));
  }

  closeDeleteTourDialog() {
    this.setState(prevState => ({
      ...prevState,
      dialogWaringOpened: false
    }));
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin()

    if (response.status == 200) {
      this.setState(prevState => ({
        ...prevState,
        isAdmin: response.data.isAdmin
      }));  
    }
  }

  render() {
    const { classes } = this.props;

    let toursItems = [];
    if (this.state.tours) {
      this.state.tours.forEach((tour) => {
        let item = {
          idInt: tour.id,
          id: `tour-${tour.id}`,
          primary: tour.name,
          secondary: tour.createdAt,
          to: `/new-tour?id=${tour.id}`,
          icon: <MapIcon/>,
          isValid: tour.isValid
        }
  
        toursItems.push(item);
      });
    }
    
    return (
      <>
        <Authenticated />
        <MainAppBar headerText="Parcours" actionText="Nouveau parcours" actionUrl="/new-tour" showAdminButton={this.state.isAdmin}/>
        <DialogWarning
          open={this.state.dialogWaringOpened}
          primaryText="Voulez vous vraiment supprimer ce circuit ?"
          secondaryText="Une fois supprimé il sera impossible de le récupérer."
          primaryAction={this.deleteTour}
          closeAction={this.closeDeleteTourDialog}
          idItem={this.state.tourIdToDelete}
          primaryButtonText="Supprimer"
          secondaryButtonText="Annuler"
        />
        { this.state.showError ? (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        ): (<div />) }
        { this.state.showSuccess ? (
          <Alert severity="success">{this.state.successMessage}</Alert>
        ): (<div />) }
        <br/>

        <div className={classes.list}>
          { this.state.toursLoaded ? (
            <DraggableList
              items={toursItems}
              onDragEnd={this.onDragEnd}
              onDelete={this.openDeleteTourDialog}
              onDuplicate={this.duplicateTour}
            />
          ) : (
            <div></div>
          )
          }
        </div>
      </>
    )
  }

}

export default withStyles(styles, { withTheme: true })(Home);