import React from "react";
import { Link } from 'react-router-dom';
import { ListItemText, ListItem, ListItemSecondaryAction, ListItemAvatar, Avatar, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopy from '@material-ui/icons/FileCopy';
import { Draggable } from 'react-beautiful-dnd';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)'
  },
  warningIcon: {
    marginLeft: "20px",
    height: "100%"
  }
});

const DraggableListItem = ({ item, index, onDelete, onDuplicate}) => {
  const classes = useStyles();

  const CustomLink = React.useMemo(
      () =>
        React.forwardRef((linkProps, ref) => (
          <Link ref={ref} to={item.to} {...linkProps} />
        )),
      [item.to],
  );

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
          button
          component={CustomLink}
        >
          <ListItemAvatar>
            <Avatar>
              {item.icon}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.primary} secondary={item.secondary} />

          <ListItemSecondaryAction>
            {onDuplicate ? (
              <Tooltip title="Dupliquer">
              <IconButton edge="end" aria-label="delete" onClick={ (e) => { onDuplicate(item.idInt) }}>
                <FileCopy/>
              </IconButton>
              </Tooltip>
            ): (
              <div />
            )
            }
            {onDelete ? (
              <Tooltip title="Supprimer">
              <IconButton edge="end" aria-label="delete" onClick={ (e) => { onDelete(item.idInt) }}>
                <DeleteIcon/>
              </IconButton>
              </Tooltip>
            ): (
              <div />
            )
            }
                      { !item.isValid ? (
              <Tooltip className={classes.warningIcon} title="Des données sont manquantes">
                <WarningRoundedIcon color="primary" />
              </Tooltip>
            ) : (<div />)
          }
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;

