import React, { Component } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import { Button } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import AuthStorage from '../../utils/authStorage';
import ApiUser from '../../API/ApiUser';
import Authenticated from '../components/authenticated';

const styles = (theme) => ({
  formContainer: {
    paddingTop: '30px',
    paddingBottom: '30px',
    width: '60ch',
    margin: 'auto'
  },
  textfield: {
    minWidth: 200,
    margin: theme.spacing(1)
  },
  validateForm: {
    textDecoration: 'none',
    margin: theme.spacing(1),
    marginTop: 20,
    marginBottom: 20,
  },
  connect: {
    color: 'blue',
    margin: theme.spacing(1)
  }
});

class RequestResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      email: ""
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.requestResetPassword = this.requestResetPassword.bind(this);
  }

  componentDidMount() {
    document.title = "Nouveau mot de passe | Landing Zone"

  }

  handleFormChange(event) {
    this.setState( prevState => ({
        ...prevState,
        [event.target.name]: event.target.value
    }));
  };
 
  async requestResetPassword(event) {
    event.preventDefault();

    if (!this.state.email) {

        return;
    }

    let res = await ApiUser.requestPasswordReset(this.state.email);

    if (res.status != 200) {
        this.setState( prevState => ({
            ...prevState,
            showError: true,
            showSuccess: false,
            errorMessage: "Cette email n'éxiste pas"
        }));
    } else {
        this.setState( prevState => ({
            ...prevState,
            showError: false,
            showSuccess: true,
            successMessage: "Un email contenant un lien pour mettre à jour votre mot de passe vous à été envoyé"
        }));
    }
  };

  render() {
    const { classes } = this.props;

    return (
    <>
     <div>
        <br/>
        { this.state.showError ? (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        ): (<div />) }
        { this.state.showSuccess ? (
          <Alert severity="success">{this.state.successMessage}</Alert>
        ): (<div />) }

        <form onSubmit={this.requestResetPassword} className={classes.formContainer}>
          <h1 >Vous souhaitez changer votre mot de passe ?</h1>
          <p>Pout changer votre mot de passe veuillez entrer votre addresse email ci-dessous. Un email avec un lien vous permettant de changer votre mot de passe vous sera envoyé.</p>
          <div >
            <div>
              <TextField
                required
                name="email"
                onChange={this.handleFormChange}
                label="Email"
                className={classes.textfield}
                />
                <br/>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.validateForm}
                >
                  Valider
              </Button>
            </div>
          </div>
          <Link className={classes.connect} to="/login">
            Connexion
          </Link>
        </form>

      </div>
    </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(RequestResetPassword));