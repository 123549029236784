export default class AuthStorage {

    static deleteTokens = () => {
        try {
            localStorage.setItem('accessToken', '')
            localStorage.setItem('refreshToken', '')
            localStorage.setItem('accessTokenExp', '')
        }
        catch (error) {
            console.log(error.message)
        }
    }

    static saveTokens = (accessToken, refreshToken, accessTokenExp) => {
        try {
            localStorage.setItem('accessToken', accessToken)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('accessTokenExp', accessTokenExp)
        }
        catch (error) {
            console.log(error.message)
        }
    }

    static getTokens = () => {
        try {
            let accessToken = localStorage.getItem('accessToken')
            let refreshToken = localStorage.getItem('refreshToken')
            let accessTokenExp =localStorage.getItem('accessTokenExp')

            if (!accessToken || !refreshToken || !accessTokenExp) {

                return null
            }

            return {
                accessToken: accessToken, 
                refreshToken: refreshToken,
                accessTokenExp: accessTokenExp
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

}
