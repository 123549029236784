import validator from 'validator';

export function validateAccount(email, siret, phoneNumber, maxNbOfTours) {
    if (!validator.isMobilePhone(phoneNumber)) {
        throw  Error("Le numéro de téléphone est incorrecte")
    }

    if (!validator.isEmail(email)) {
        throw Error("L'email est incorrecte")
    }

    if (siret.length != 14) {
        throw Error("Le numéro siret doit contenir 14 chiffres")
    }

    let maxToursInt = Number(maxNbOfTours)

    if (!maxNbOfTours) {
        throw Error("Le nombre maximum de parcours doit être un nombre")
    }

    if (maxToursInt > 100) {
        throw Error("Le nombre maximal de parcours ne peux éxcéder 100")
    }
}