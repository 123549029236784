import React from "react";
import { Link } from 'react-router-dom';
import { ListItemText, ListItem, ListItemSecondaryAction, ListItemAvatar, Avatar, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function ListItemLink(props) {
    const { icon, primary, secondary, to, deleteButton, disabled } = props;

    const CustomLink = React.useMemo(
      () =>
        React.forwardRef((linkProps, ref) => (
          <Link ref={ref} to={to} {...linkProps} />
        )),
      [to],
    );
  
    return (
        <ListItem button component={CustomLink} disabled={disabled}>
          <ListItemText primary={primary} secondary={secondary}  style={{ color: '#1d1d1d' }} />
          <ListItemSecondaryAction>
            { deleteButton ?
            (
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon/>
              </IconButton>
            ) : (<div/>)
            }
          </ListItemSecondaryAction>
        </ListItem>
    );
}