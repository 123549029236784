import React, { Component } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import { Button } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import qs from 'qs';

import AuthStorage from '../../utils/authStorage';
import ApiUser from '../../API/ApiUser';
import Authenticated from '../components/authenticated';

const styles = (theme) => ({
  formContainer: {
    paddingTop: '30px',
    paddingBottom: '30px',
    width: '60ch',
    margin: 'auto'
  },
  textfield: {
    minWidth: 200,
    margin: theme.spacing(1)
  },
  validateForm: {
    textDecoration: 'none',
    marginTop: 20,
    marginBottom: 20,
    margin: theme.spacing(1)
  },
  connect: {
    color: 'blue',
    margin: theme.spacing(1)
  }
});

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      newPassword: "",
      newPasswordVerify: "",
      token: ""
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount() {
    document.title = "Changer de mot de passe | Landing Zone"

    let token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token;
    if (!token) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Le lien est invalide",
          showSuccess: false,
        }));

        return;
      }

    this.setState( prevState => ({
        ...prevState,
        token: token
    }));

  }

  handleFormChange(event) {
    this.setState( prevState => ({
        ...prevState,
        [event.target.name]: event.target.value
    }));

    console.log("state: ", this.state)
  };
 
  async changePassword(event) {
    event.preventDefault();

    let newPassword = this.state.newPassword;
    let newPasswordVerify = this.state.newPasswordVerify;

    if (newPassword != newPasswordVerify) {
        this.setState( prevState => ({
            ...prevState,
            showError: true,
            showSuccess: false,
            errorMessage: "Les mots de passes ne corréspondent pas"
        }));

        return;
    }

    console.log("New pass: ", newPassword);
    console.log("New pass length: ", newPassword.length)

    if (newPassword.length < 8) {
        this.setState( prevState => ({
            ...prevState,
            showError: true,
            showSuccess: false,
            errorMessage: "Votre mot de passe doit contenir au minimum 8 carractères"
        }));

        return;
    }

    if (!this.state.token) {

        return
    }

    let res = await ApiUser.resetPassword(this.state.token, newPassword);
    console.log("res status ", res)

    if (res.status == 408) {
        this.setState( prevState => ({
            ...prevState,
            showError: true,
            showSuccess: false,
            errorMessage: "Ce lien n'est plus valide"
        }));
    } else if (res.status != 200) {
        this.setState( prevState => ({
            ...prevState,
            showError: true,
            showSuccess: false,
            errorMessage: "Ce lien n'est pas valide"
        }));
    } else {
        this.setState( prevState => ({
            ...prevState,
            showError: false,
            showSuccess: true,
            successMessage: "Votre mot de passe à été réinitialisé"
        }));
    }

  };

  render() {
    const { classes } = this.props;

    return (
     <div>
        <br/>
        { this.state.showError ? (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        ): (<div />) }
        { this.state.showSuccess ? (
          <Alert severity="success">{this.state.successMessage}</Alert>
        ): (<div />) }

        <form onSubmit={this.changePassword} className={classes.formContainer}>
          <h1 >Nouveau mot de passe</h1>
          <div >
            <div>
              <TextField
                name="newPassword"
                onChange={this.handleFormChange}
                label="Nouveau mot de passes"
                className={classes.textfield}
                type="password"
                />
                <br/>
              <TextField
                name="newPasswordVerify"
                onChange={this.handleFormChange}
                label="Confirmer le mot de passe"
                className={classes.textfield}
                type="password"
                />
                <br/>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.validateForm}
                >
                  Valider
              </Button>
              <br/>
              <Link className={classes.connect} to="/login">
                Connexion
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(ResetPassword));