import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { Redirect } from "react-router-dom";
import AccountIcon from '@material-ui/icons/AccountCircle';
import List from '@material-ui/core/List';
import DoneIcon from '@material-ui/icons/Done';
import MapIcon from '@material-ui/icons/Map';

import ApiAdmin from "../../API/ApiAdmin";
import ApiUser from "../../API/ApiUser";
import DraggableList from "../components/draggableList";
import ListItemLink from '../components/listItemLink';
import DialogWarning from '../components/dialogWarning';
import Authenticated from '../components/authenticated';
import AuthStorage from "../../utils/authStorage";
import MainAppBar from "../components/MainAppBar";

import { apiHost } from '../../constants/ApiConstants';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    margin: theme.spacing(5),
    borderRadius: 5,
    listStyle: 'none'
  }
});

class ManageAccounts extends React.Component {

  constructor(props) {
    super(props);

    console.log("OKOK")

    this.state = {
      isAdmin: false,
      isAdminSet: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      accountsLoaded: false,
      dialogWaringOpened: false,
      userIdToDelete: -1,
      accounts: [
        {
          company: "",
          email: "",
          createdAt: "",
          id: 0
        }
      ]
    }

    this.onDragEnd = this.onDragEnd.bind(this);

    this.deleteAccount = this.deleteAccount.bind(this);
    this.openDeleteAccountDialog = this.openDeleteAccountDialog.bind(this);
    this.closeDeleteAccountDialog = this.closeDeleteAccountDialog.bind(this);
  }

  componentDidMount() {
    document.title = "Comptes clients | Landing Zone"

    this.setStateIsAdmin()
    this.getAccounts();
  }

  async deleteAccount(userId) {

    ApiAdmin.deleteUserAccount(userId).then((response) => {

        if (response.status != 200) {

          this.setState(prevState => ({
            ...prevState,
            showError: true,
            showSuccess: false,
            successMessage: "Le profile n'a pas pu être supprimé",
          }));
          return
        }

        let newAccounts = this.state.accounts;
        this.state.accounts.forEach( (account, index) => {
          if (account.id == userId) {
            newAccounts.splice(index, 1);
          }
        });

        this.setState(prevState => ({
          ...prevState,
          accounts: newAccounts,
          showError: false,
          showSuccess: true,
          dialogWaringOpened: false,
          successMessage: "Le profile à bien été supprimé",
        }));
    })
  }

  openDeleteAccountDialog(userId) {
    this.setState(prevState => ({
      ...prevState,
      userIdToDelete: userId,
      dialogWaringOpened: true
    }));
  }

  closeDeleteAccountDialog() {
    this.setState(prevState => ({
      ...prevState,
      dialogWaringOpened: false
    }));
  }

  async getAccounts() {

    ApiAdmin.getUsersAccounts().then((response) => {

        var dateOptions = {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        };

        let accountsDatas = []

        if (response.status != 200 || !response.data || !response.data.accounts) {

            return
        }

        response.data.accounts.forEach((account, i ) => {
            accountsDatas.push({
                company: account.companyName,
                email: account.email,
                createdAt: new Date(account.createdAt).toLocaleDateString("fr", dateOptions),
                id: account.id
            })
        })

        this.setState(prevState => ({
            ...prevState,
            accountsLoaded: true,
            accounts: accountsDatas
          }));
    })
  }

  onDragEnd = ({ destination, source }) => {
    // // dropped outside the list
    // if (!destination) return;

    // const toursDatas = this.state.tours;

    // const [tourRemoved] = toursDatas.splice(source.index, 1);
    // toursDatas.splice(destination.index, 0, tourRemoved);

    // toursDatas.forEach((tour, id) => {
    //   tour.orderId = id;
    // });

    // this.setState(prevState => ({
    //   ...prevState,
    //   toursOrderIdChanged: true,
    //   toursLoaded: true,
    //   toursDatas: toursDatas
    // }), async () => {
    //   let res = await ApiTour.updateToursOrdersIds(this.state.tours);
    // });

  };

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin()

    if (response.status == 200) {
      this.setState(prevState => ({
        ...prevState,
        isAdmin: response.data.isAdmin
      }));  
    }
  }

  render() {
    const { classes } = this.props;

    let accountsItems = [];
    if (this.state.accounts) {
      this.state.accounts.forEach((account) => {
        let item = {
          idInt: account.id,
          id: `account-${account.id}`,
          primary: account.company,
          secondary: account.email,
          to: `/admin/manage-account?id=${account.id}`,
          icon: <AccountIcon/>,
          isValid: true
        }
  
        accountsItems.push(item);
      });
    }

    if (!this.state.isAdmin && !this.state.isAdminSet) {

        return (
            <div />
        )
    } else if (!this.state.isAdmin && this.state.isAdminSet) {

        return (
            <Redirect
            to={{
              pathname: "/"
            }}
        />
        )
    }
    
    return (
      <>
        <Authenticated />
        <MainAppBar
          headerText="Comptes clients"
          actionText="Ajouter un utilisateur"
          actionUrl="/admin/signup-user"
          showHomeButton={true}
          showAdminButton={this.state.isAdmin}
        />
        { this.state.showError ? (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        ): (<div />) }
        { this.state.showSuccess ? (
          <Alert severity="success">{this.state.successMessage}</Alert>
        ): (<div />) }
        <br/>

        <div className={classes.list}>

        <DialogWarning
          open={this.state.dialogWaringOpened}
          primaryText="Voulez vous vraiment supprimer cette utilisateur ?"
          secondaryText="Une fois supprimé il sera impossible de le récupérer."
          primaryAction={this.deleteAccount}
          closeAction={this.closeDeleteAccountDialog}
          idItem={this.state.userIdToDelete}
          primaryButtonText="Supprimer"
          secondaryButtonText="Annuler"
        />

        { this.state.accountsLoaded ? (
          <DraggableList
            items={accountsItems}
            onDragEnd={this.onDragEnd}
            onDelete={this.openDeleteAccountDialog}
          />
        ) : (
          <div></div>
        )
        }
        </div>
      </>
    )
  }

}

export default withStyles(styles, { withTheme: true })(ManageAccounts);