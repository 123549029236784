import React from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar,  Typography, Button, Slider, Divider, IconButton, colors, Box, Switch, FormControlLabel, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
import qs from 'qs';
import Alert from '@material-ui/lab/Alert';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CategoryIcon from '@material-ui/icons/Category';
import QRCode from 'qrcode.react';
import { BlockPicker, SketchPicker } from 'react-color';

import ApiUser from '../../API/ApiUser';
import ApiZone from '../../API/ApiZone';
import ApiEnigma from '../../API/ApiEnigma';
import DraggableList from "../components/draggableList";
import ListItemLink from '../components/listItemLink';
import DialogWarning from '../components/dialogWarning'
import Authenticated from '../components/authenticated';
import MainAppBar from "../components/MainAppBar";
import LoadingView from '../components/loadingView';
import { GameMode, ZoneMode } from "../../constants/TourConstants";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  formContainer: {
    paddingTop: '30px',
    paddingBottom: '30px'
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '20ch'
    }
  },
  imageCard: {
    marginLeft: 10,
    maxWidth: 200,
    marginTop: 20,
  },
  imageCardMedia: {
    width: "100%",
    height: "100%"
  },
  uploadFile: {
    marginTop: 10,
    marginLeft: 10
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  enigmasButtons: {
    margin: theme.spacing(1),
  },
  link: {
    textDecoration: 'none'
  },
  textFieldBig: {
    minWidth: 400
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderRadius: 5,
    listStyle: 'none'
  },
  buttonListPlus: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    color: 'white'
  },
  returnButtonLink : {
    textDecoration: 'none',
    margin: theme.spacing(2)
  },
  switch: {
    display: 'flex',
    justifyContent: 'center',
    color: 'black',
    backgroundColor: 'lightGray',
    borderRadius: 5
  }
});

class NewZone extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      zoneLoaded: false,
      enigmasLoaded: false,
      enigmaOrderIdChanged: false,
      editing: false,
      tourId: 9999,
      zoneId: 9999,
      dialogWaringOpened: false,
      loadingViewOpened: false,
      enigmaIdToDelete: -1,
      qrCodeBgColor: "#ffffff",
      qrCodeFgColor: "#000000",
      enigmasDatas : [
        {
          title: "",
          id: 9999,
          orderId: 0,
          isValid: false
        }
      ],
      formDatas : {
        name: "",
        zoneRadius: 60,
        points: 100,
        timer: 6,
        zoneMode: ZoneMode.MAP,
        coords: {
          longitude: 0,
          latitude: 0
        },
        isActive: true
      }
    }

    this.submitForm = this.submitForm.bind(this);

    this.updateName = this.updateName.bind(this);
    this.updateZoneRadius = this.updateZoneRadius.bind(this);
    this.updatePoints = this.updatePoints.bind(this);
    this.updateTimer = this.updateTimer.bind(this);
    this.updateCoordsLon = this.updateCoordsLon.bind(this);
    this.updateCoordsLat = this.updateCoordsLat.bind(this);
    this.updateIsActive = this.updateIsActive.bind(this);
    this.handleZoneModeChange = this.handleZoneModeChange.bind(this);
    this.handleQrCodeBackgroundColorChanged = this.handleQrCodeBackgroundColorChanged.bind(this);
    this.handleQrCodeForegroundColorChanged = this.handleQrCodeForegroundColorChanged.bind(this);

    this.onDragEnd = this.onDragEnd.bind(this);
    this.deleteEnigma = this.deleteEnigma.bind(this);
    this.openDeleteEnigmaDialog = this.openDeleteEnigmaDialog.bind(this);
    this.closeDeleteEnigmaDialog = this.closeDeleteEnigmaDialog.bind(this);
    this.downloadQR = this.downloadQR.bind(this);
  }


  componentDidMount() {
    document.title = "Zone | Landing Zone"

    let tourId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).tourId;
    let zoneId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).zoneId;

    let tourIdInt = parseInt(tourId);
    let zoneIdInt = parseInt(zoneId);

    this.setStateIsAdmin()

    if (!tourId || !tourIdInt) {
      this.setState(prevState => ({
        ...prevState,
        showError: true,
        errorMessage: "Une erreur est survenue. Impossible de trouver les données liées à cette zone",
        showSuccess: false,
      }));
    }

    this.setState(prevState => ({
      ...prevState,
      tourId: tourIdInt,
    }));

    if (!zoneId || !zoneIdInt)
      return

    ApiZone.getZone(tourIdInt, zoneIdInt).then((zoneDatas) => {
      if (!zoneDatas || zoneDatas.status !== 200) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Une erreur est survenue lors de la récupération du circuit",
          showSuccess: false,
        }));
        
        return;
      }

      this.setForm(zoneDatas.data, tourIdInt, zoneIdInt)
    });

    ApiEnigma.getEnigmas(zoneIdInt).then((enigmas) => {
      let enigmasDatas = [];

      if (enigmas.status !== 200 && enigmas.status !== 204) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Une erreur est survenue lors de la récupération des énigmes",
          showSuccess: false,
        }));
  
        return;
      }


      if (enigmas.status == 204)
        return;

      enigmas.data.forEach((elem, i) => {
        enigmasDatas.push({
          title: elem.title,
          id: elem.id,
          isValid: elem.isValid
        });
      });

      this.setState(prevState => ({
        ...prevState,
        enigmasDatas: enigmasDatas,
        enigmasLoaded: true
      }));
    });
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin()

    if (response.status == 200) {
      this.setState(prevState => ({
        ...prevState,
        isAdmin: response.data.isAdmin
      }));  
    }
  }

  handleZoneModeChange(event, value) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        zoneMode: event.target.value
      }
    }));
  }

  downloadQR = () => {
    const canvas = document.getElementById("zone_qr_code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = this.state.formDatas.name + "_QRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  setForm(zoneDatas, tourId, zoneId) {

    this.setState( prevState => ({
      ...prevState,
      zoneLoaded: true,
      editing: true,
      tourId: tourId,
      zoneId: zoneId,
      formDatas : {
          ...prevState.formDatas,
          zoneMode: zoneDatas.zoneMode,
          name: zoneDatas.name,
          zoneRadius: zoneDatas.zoneRadius,
          points: zoneDatas.points,
          timer: zoneDatas.timer,
          coords: {
            longitude: zoneDatas.coordsLongitude,
            latitude: zoneDatas.coordsLatitude
          },
          isActive: zoneDatas.isActive
        }
    }));
  }

  updateName(event) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        name: event.target.value
      }
    }));
  }

  updateZoneRadius(event, value) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        zoneRadius: parseInt(value)
      }
    }));
  }

  updatePoints(event, value) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        points: parseInt(value)
      }
    }));
  }

  updateTimer(event, value) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        timer: parseInt(value)
      }
    }));
  }

  updateIsActive(event, value) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        isActive: value
      }
    }));
  }

  updateCoordsLon(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value)))
      return;

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        coords: {
          ...prevState.formDatas.coords,
          longitude: parseFloat(event.target.value)
        }
      }
    }));
  }

  updateCoordsLat(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value)))
      return;

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        coords: {
          ...prevState.formDatas.coords,
          latitude: parseFloat(event.target.value)
        }
      }
    }));
  }

  async submitForm(event) {
    event.preventDefault();

    var serverResp = null;

    this.setState(prevState => ({
      ...prevState,
      loadingViewOpened: true
    }));
    if (this.state.editing) {
      serverResp = await ApiZone.updateZone(this.state.formDatas, this.state.tourId, this.state.zoneId);
      if (this.state.enigmaOrderIdChanged) {
        let res = await ApiEnigma.updateEnigmaOrdersIds(this.state.enigmasDatas);
      }
    } else {
      serverResp = await ApiZone.uploadZone(this.state.formDatas, this.state.tourId);
    }

    this.setState(prevState => ({
      ...prevState,
      loadingViewOpened: false
    }));
    if (serverResp.status != 201 && serverResp.status != 200) {
      this.setState(prevState => ({
        ...prevState,
        showError: true,
        errorMessage: `Une érreur est survenue lors de la communication avec le serveur`,
        showSuccess: false,
      }));
    
      return;
    } else {
      this.setState(prevState => ({
        ...prevState,
        editing: true,
        showSuccess: true,
        successMessage: "La zone à bien été sauvegardé",
        showError: false,
        zoneId: serverResp.data.zoneId
      }));
    }
  }

  onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const enigmasDatas = this.state.enigmasDatas;

    const [enigmaRemoved] = enigmasDatas.splice(source.index, 1);
    enigmasDatas.splice(destination.index, 0, enigmaRemoved);

    enigmasDatas.forEach((enigma, id) => {
      enigma.orderId = id;
    });

    this.setState(prevState => ({
      ...prevState,
      enigmaOrderIdChanged: true,
      enigmasDatas: enigmasDatas
    }));
  };

  async deleteEnigma(enigmaId) {
    let res = await ApiEnigma.deleteEnigma(enigmaId);

    if (res.status == 200) {
      let newEnigmas = this.state.enigmasDatas;
      newEnigmas.forEach( (enigma, index) => {
        if (enigma.id == enigmaId) {
          newEnigmas.splice(index, 1);
        }
      });
      this.setState(prevState => ({
        ...prevState,
        showSuccess: true,
        successMessage: "L'énigme à bien été supprimé",
        showError: false,
        dialogWaringOpened: false,
        enigmasDatas: newEnigmas
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        showError: true,
        errorMessage: "Une érreur est survenue durant la suppréssion de l'énigma",
        showSuccess: false,
        dialogWaringOpened: false,
      }));
    }
  }

  handleQrCodeBackgroundColorChanged(color) {
    this.setState(prevState => ({
      ...prevState,
      qrCodeBgColor: color.hex.toString()
    }));
  }

  handleQrCodeForegroundColorChanged(color) {
    this.setState(prevState => ({
      ...prevState,
      qrCodeFgColor: color.hex.toString()
    }));
  }

  openDeleteEnigmaDialog(enigmaId) {
    this.setState(prevState => ({
      ...prevState,
      enigmaIdToDelete: enigmaId,
      dialogWaringOpened: true
    }));
  }

  closeDeleteEnigmaDialog() {
    this.setState(prevState => ({
      ...prevState,
      dialogWaringOpened: false
    }));
  }

  render() {
    const { classes } = this.props;

    let enigmasItems = [];

    if (this.state.enigmasDatas) {
      this.state.enigmasDatas.forEach((enigma) => {
        let item = {
          id: `enigma-${enigma.id}`,
          idInt: enigma.id,
          primary: enigma.title,
          secondary: "",
          to: `/new-enigma?tourId=${this.state.tourId}&zoneId=${this.state.zoneId}&enigmaId=${enigma.id}`,
          icon: <CategoryIcon/>,
          isValid: enigma.isValid
        }
  
        enigmasItems.push(item);
      });
    }

    return (
    <>
      <Authenticated />
      <MainAppBar headerText="Zone" showHomeButton={true}  showAdminButton={this.state.isAdmin}/>
      <Container className={classes.formContainer} maxWidth="md">
      <form className={classes.form} autoComplete="off" onSubmit={this.submitForm}>

        <FormControlLabel
          className={classes.switch}
          control={
            <Switch checked={this.state.formDatas.isActive} onChange={this.updateIsActive} />
          }
          label="Activer/Désactiver la zone"
        />
        <br/>

        <TextField
          required
          id="filled-required"
          label="Titre de la zone"
          variant="filled"
          defaultValue={this.state.formDatas.name}
          onChange={this.updateName}
          className={classes.textFieldBig}
          multiline={true}
          inputProps={{ maxLength: 1000 }}
          key={this.state.zoneLoaded ? 'nameNotLoaded' : 'nameLoaded'}
        />

        <br/><br/><Divider/><br/>

        <FormControl className={classes.formControl}>
          <InputLabel id="select-response-type">Type</InputLabel>
            <Select
              labelId="select-response-type"
              id="select-response-type"
              value={this.state.formDatas.zoneMode}
              onChange={this.handleZoneModeChange}
            >
              <MenuItem value={ZoneMode.MAP}>Map</MenuItem>
              <MenuItem value={ZoneMode.QRCODE}>QRCode</MenuItem>
          </Select>
        </FormControl>

        { this.state.formDatas.zoneMode == ZoneMode.MAP ? (
        <>
          <br/><br/>
          <Typography id="discrete-slider" gutterBottom>
            Périmètre de la zone (mètres)
          </Typography>
          <Slider
            getAriaValueText={this.valueSliderTourTimer}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={5}
            marks
            min={5}
            max={300}
            value={this.state.formDatas.zoneRadius}
            onChange={this.updateZoneRadius}
            variant="filled"
            key={this.state.zoneLoaded ? 'zoneRadiusNotLoaded' : 'zoneRadiusLoaded'}
          />
          <TextField
            required
            id="filled-required"
            label="Longitude"
            variant="filled"
            defaultValue={this.state.formDatas.coords.longitude != 0 ? this.state.formDatas.coords.longitude : ""}
            onChange={this.updateCoordsLon}
            inputProps={{ className: classes.input, pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?" }}
            key={this.state.zoneLoaded ? 'coordsLonNotLoaded' : 'coordsLonLoaded'}
          />
          <TextField
              required
              id="filled-required"
              label="Latitude"
              variant="filled"
              defaultValue={this.state.formDatas.coords.latitude != 0 ? this.state.formDatas.coords.latitude : ""}
              onChange={this.updateCoordsLat}
              inputProps={{ className: classes.input, pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?" }}
              key={this.state.zoneLoaded ? 'coordsLatNotLoaded' : 'coordsLatLoaded'}
          />
        </>
        ) : (
          <>
            <br/>
            <br/>
            <Box sx={{ width: 150 }} >
              <QRCode
                id="zone_qr_code"
                size={150} 
                value={this.state.zoneId.toString()}
                bgColor={this.state.qrCodeBgColor}
                fgColor={this.state.qrCodeFgColor}
              />
              <Button onClick={this.downloadQR} variant="outlined" color="primary">
                Télécharger
              </Button>
              <Box sx={{ display: 'inline-flex' }}>
                <Box sx={{ m: 1 }}>
                  <Typography id="bgColor" gutterBottom>
                    Couleur de fond
                  </Typography>
                  <BlockPicker
                    styles={{ margin: "100px" }}
                    color={ this.state.qrCodeBgColor }
                    onChangeComplete={ this.handleQrCodeBackgroundColorChanged }
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography id="fgColor" gutterBottom>
                    Couleur du QRCode
                  </Typography>
                  <BlockPicker
                    color={ this.state.qrCodeFgColor }
                    onChangeComplete={ this.handleQrCodeForegroundColorChanged }
                  />
                </Box>
              </Box>
            </Box>
          </>
        ) }

        <br/><br/><Divider/><br/>
        <Typography id="discrete-slider" gutterBottom>
          Nombre de points à gagner
        </Typography>
        <Slider
          getAriaValueText={this.valueSliderTourTimer}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={10}
          marks
          min={10}
          max={500}
          value={this.state.formDatas.points}
          onChange={this.updatePoints}
          variant="filled"
          key={this.state.zoneLoaded ? 'pointsNotLoaded' : 'pointsLoaded'}
        />
      <br/><br/><Divider/><br/>
      <Typography id="discrete-slider" gutterBottom>
          Durée limite de la zone (minutes)
        </Typography>
        <Slider
          getAriaValueText={this.valueSliderTourTimer}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={1}
          max={30}
          value={this.state.formDatas.timer}
          onChange={this.updateTimer}
          variant="filled"
          key={this.state.zoneLoaded ? 'timerNotLoaded' : 'timerLoaded'}
        />
      <br/><br/><Divider/><br/>

      <div className={classes.list}>

        { this.state.enigmasLoaded ? (
          <DraggableList items={enigmasItems} onDragEnd={this.onDragEnd}  onDelete={this.openDeleteEnigmaDialog}/>
        ) : (
          <div></div>
        )
        }
        <Box className={classes.buttonListPlus}>
          <ListItemLink
            to={`/new-enigma?tourId=${this.state.tourId}&zoneId=${this.state.zoneId}`}
            key={1000} primary="NOUVELLE ÉNIGME" icon={<AddCircleOutlineIcon/>}
            deleteButon={false}
            disabled={this.state.zoneId != 9999 ? false : true}
          />
        </Box>
      </div>

        { this.state.showError ? (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        ): (<div />) }
        { this.state.showSuccess ? (
          <Alert severity="success">{this.state.successMessage}</Alert>
        ): (<div />) }
      <br/>

      <DialogWarning
          open={this.state.dialogWaringOpened}
          primaryText="Voulez vous vraiment supprimer cette énigme ?"
          secondaryText="Une fois supprimé il sera impossible de la récupérer."
          primaryAction={this.deleteEnigma}
          closeAction={this.closeDeleteEnigmaDialog}
          idItem={this.state.enigmaIdToDelete}
          primaryButtonText="Supprimer"
          secondaryButtonText="Annuler"
        />
        <LoadingView
          className={classes.loadingView}
          open={this.state.loadingViewOpened}
        />

      <br/><br/><Divider/><br/>

      
      <Button variant="outlined" color="primary" type="submit">
        ENREGISTRER
      </Button>
      <Link className={classes.returnButtonLink} to={`/new-tour?id=${this.state.tourId}`}>
        <Button variant="outlined" color="darkGrey" >
          RETOUR
        </Button>
      </Link>

      </form>
      </Container>
      </>
    )
  }

}

export default withStyles(styles, { withTheme: true })(NewZone);