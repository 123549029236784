import React from 'react';
import { AppBar, Toolbar, Typography, Button, Divider, IconButton } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/HomeRounded';
import AccountIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import ExitToApp from '@material-ui/icons/ExitToApp';
import SupervisorAccount from '@material-ui/icons/RecentActors';

import AuthStorage from '../../utils/authStorage';

const styles = (theme) => ({
    appBar: {
      backgroundColor: theme.palette.darkGrey.main
    },
    title: {
        flexGrow: 1,
        marginLeft: 13,
        color: "lightgray"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
      paddingBottom: 10,
      alignSelf: 'flex-end',
      "&:hover": {
        "& $iconText": {
          color: "lightgray"
        },
        "& $icon": {
          fill: "lightgray",
        }
      },
      "&:hover:last-child": {
        "& $icon": {
          fill: "red",
        }
      }
    },
    homeIcon: {
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'center'
    },
    mainLinkAction: {
      textDecoration: 'none',
      flexGrow: 1,
      align: "center"
    },
    label: {
      color: 'white',
      flexDirection: 'column'
    },
    icon: {
      fontSize: '28px !important'
    },
    iconText: {
      fontSize: '14px'
    }
  });

class MainAppBar extends React.Component {
  constructor(props) {
    super(props);
    const { headerText, actionText, actionUrl, showAdminButton, showHomeButton } = props;

    this.state = { }

    this.logout = this.logout.bind(this)
  }

  componentDidMount() {
  }

  logout() {
    AuthStorage.deleteTokens()
  }

  render() {
    const { classes } = this.props;

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar backgroundcolor="primary">
                { this.props.showHomeButton || !this.props.actionUrl || !this.props.actionText ? (
                  <Link to="/" className={classes.homeIcon}>
                    <IconButton aria-label="acceuil" edge="end" color="secondary">
                        <HomeIcon />
                    </IconButton>
                  </Link>
                ) : (
                  <div />
                )}
                <Typography variant="h6" className={classes.title}>
                  {this.props.headerText}
                </Typography>
                { this.props.actionUrl && this.props.actionText ? (
                  <Link to={this.props.actionUrl} className={classes.mainLinkAction}>
                    <Button variant="contained" color="secondary">{this.props.actionText}</Button>
                  </Link>
                ): (
                  <div />
                )}
                { this.props.showAdminButton ? (
                  <Link to="/admin/manage-accounts" className={classes.link}>
                    <IconButton edge="end" aria-label="delete" classes={{ label: classes.label }}>
                      <SupervisorAccount aria-label="acceuil" edge="end" style={{ color: 'white' }}  className={classes.icon}/>
                      <Typography className={classes.iconText}>Clients</Typography>
                    </IconButton>
                  </Link>
                ) : (<div/>) }
                  <a target="_blank" href="https://landingzone.fr/aide/" className={classes.link}>
                    <IconButton edge="end" aria-label="delete" classes={{ label: classes.label }}>
                      <HelpIcon aria-label="Mon compte" edge="end" style={{ color: 'white' }}  className={classes.icon}/>
                      <Typography className={classes.iconText}>Aide</Typography>
                    </IconButton>
                  </a>
                  <Link to="/user/account" className={classes.link}>
                    <IconButton edge="end" aria-label="delete" classes={{ label: classes.label }}>
                      <AccountIcon aria-label="Mon compte" edge="end" style={{ color: 'white' }}  className={classes.icon}/>
                      <Typography className={classes.iconText}>Compte</Typography>
                    </IconButton>
                  </Link>
                  <Link to="/login" className={classes.link} onClick={this.logout}>
                    <IconButton edge="end" aria-label="delete" classes={{ label: classes.label }}>
                      <ExitToApp aria-label="Déconnexion" edge="end" style={{ color: 'red' }} className={classes.icon}/>
                      <Typography className={classes.iconText}>Déconnexion</Typography>
                    </IconButton>
                  </Link>
            </Toolbar>
        </AppBar>
    );
  }
};

export default withStyles(styles, { withTheme: true })(MainAppBar);
