import Axios from 'axios';
import { apiHost } from '../constants/ApiConstants';
import JwtUtils from '../utils/jwtUtils';

export default class ApiUser {

    static async signin(email, password) {
        const authHeader = 'Basic '+ new Buffer(`${email}:${password}`).toString('base64');

        console.log("apihost: ", apiHost)

        return await Axios.post(
            `${apiHost}/user/signin`, {},
            {
                headers: {'Authorization': authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            console.log("LOGIN response: ", response.data)

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async isAdmin() {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        
        const authHeader = 'Bearer ' + tokens.accessToken;
        console.log("Auth header -> ", authHeader)

        return await Axios.get( `${apiHost}/user/isAdmin`, {
                headers: {"Authorization": authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = { status: error.response.status, data: error.response.data }
            console.log("Error ->", error)

            return errorLog;
        });
    }

    static async getAccount() {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        
        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.get( `${apiHost}/user/profile`, {
                headers: {"Authorization": authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async getAnalytics() {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        
        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.get( `${apiHost}/user/analytics`, {
                headers: {"Authorization": authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async updateAccount(
        firstName,
        lastName,
        company,
        address,
        siret,
        phoneNumber
    ) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        
        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.post( `${apiHost}/user/profile`, {
            firstName: firstName,
            lastName: lastName,
            company: company,
            address: address,
            siret: siret,
            phoneNumber: phoneNumber
        }, {
                headers: {"Authorization": authHeader}
        }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async refreshToken(refreshToken) {
        const authHeader = 'Bearer ' + refreshToken;

        console.log("REFRESH_TOKEN token sent: ", refreshToken)

        return await Axios.get(
            `${apiHost}/user/refreshToken`,
            {
                headers: {'Authorization': authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async requestPasswordReset(email) {
        return await Axios.post(
            `${apiHost}/user/requestNewPassword`,
            {
                email: email
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }
    
    static async resetPassword(token, newPassword) {
        return await Axios.post(
            `${apiHost}/user/resetPassword`,
            {
                token: token,
                newPassword: newPassword
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }
}