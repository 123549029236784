import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { Redirect } from "react-router-dom";
import AccountIcon from '@material-ui/icons/AccountCircle';
import List from '@material-ui/core/List';
import DoneIcon from '@material-ui/icons/Done';
import MapIcon from '@material-ui/icons/Map';
import { Divider, Container, Button, InputLabel, Select, MenuItem  } from "@material-ui/core";
import { Link } from 'react-router-dom';

import ApiTour from "../../API/ApiTour";
import ApiAdmin from "../../API/ApiAdmin";
import ApiUser from "../../API/ApiUser";
import DraggableList from "../components/draggableList";
import ListItemLink from '../components/listItemLink';
import DialogWarning from '../components/dialogWarning';
import Authenticated from '../components/authenticated';
import AuthStorage from "../../utils/authStorage";
import MainAppBar from "../components/MainAppBar";
import { validateAccount } from "../../utils/validateAccount";
import { BarChart } from "../components/BarChart"
import BarChartUtils from "../../utils/barChart"

import { apiHost } from '../../constants/ApiConstants';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
      },
      appBar: {
        backgroundColor: theme.palette.darkGrey.main
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
      formContainer: {
        paddingTop: '30px',
        paddingBottom: '30px'
      },
      form: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '20ch'
        }
      },
      extendedIcon: {
        marginRight: theme.spacing(1),
      },
      link: {
        textDecoration: 'none'
      },
      textField: {
        minWidth: 200
      },
      list: {
        backgroundColor: theme.palette.lightGrey.main,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        borderRadius: 5,
        listStyle: 'none'
      },
      buttonListPlus: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 5,
        color: 'white'
      },
      returnButtonLink : {
        textDecoration: 'none',
        margin: theme.spacing(2)
      },
      danger: {
        color: 'red'
      }
});

class Account extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      isAdminSet: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      accountLoaded: false,
      account:
        {
          firstName: "",
          lastName: "",
          company: "",
          email: "",
          phoneNumber: "",
          createdAt: "",
          streetAddress: "",
          city: "",
          zipCode: -1,
          siret: "",
          maxNbOfTours: -1,
          secretCode: ""
        },
      analyticsFromApi: null,
      analytics: { datasets: [] },
      monthOffset: 999,
      tourNames: []
    }

    this.submitForm = this.submitForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.updateMonthOffset = this.updateMonthOffset.bind(this);

  }

  componentDidMount() {
    document.title = "Mon compte | Landing Zone"

    this.setStateIsAdmin()
    this.getAccount();
    this.getAnalytics()
  }

  async updateMonthOffset(event) {
    let target = event.target;
    let value = target.value;

    let analyticsDatasFormatted = await BarChartUtils.formatDatasChart(this.state.analyticsFromApi, this.state.tourNames, value)

    this.setState(prevState => ({
        ...prevState,
        analytics: analyticsDatasFormatted,
        monthOffset: value
    }));
  }

  async getAnalytics() {
    let analytics = await ApiUser.getAnalytics();

    if (analytics.status != 200 || !analytics.data) {
      return
    }

    let analyticsDatas = [];
    let tourNames = [];


    for (var i in analytics.data) {
      console.log("Sending tour id ", analytics.data[i].TourId)
      let tour = await ApiTour.getTour(analytics.data[i].TourId);
      let tourName = "";
      if (tour.status == 200 && tour.data) {
        tourName = tour.data.title
      }
      if (!tourNames.includes(tourName) && tourName != "") {
        tourNames.push(tourName);
      }

      analyticsDatas.push({
        tourName: tourName,
        id: analytics.data[i].id,
        createdAt: analytics.data[i].created_at,
        score: analytics.data[i].score,
        distanceTraveled: analytics.data[i].distanceTraveled
      });
    };
    let analyticsDatasFormatted = await BarChartUtils.formatDatasChart(analyticsDatas, tourNames, null)

    this.setState(prevState => ({
      ...prevState,
      analyticsLoaded: true,
      analytics: analyticsDatasFormatted,
      tourNames: tourNames,
      analyticsFromApi: analyticsDatas
    }));
  }

  async getAccount() {

    ApiUser.getAccount().then((response) => {

      console.log("Resp : ", response.data, response.status)

        if (response.status != 200 || !response.data) {
            this.setState(prevState => ({
                ...prevState,
                showError: true,
                showSuccess: false,
                errorMessage: "Une érreur est survenu"
            }))

            return
        }
        let address = response.data.address.split(';');
        if (!address) {
            this.setState(prevState => ({
                ...prevState,
                showError: true,
                showSuccess: false,
                errorMessage: "Une érreur est survenu"
            }))

            return
        }
        if (address.length != 3) {
          address.push("");
          address.push("");
        }

        this.setState(prevState => ({
            ...prevState,
            accountLoaded: true,
            account: {
                firstName: response.data.firstname,
                lastName: response.data.lastname,
                company: response.data.companyName,
                phoneNumber: response.data.phoneNumber,
                createdAt: response.data.createdAt,
                streetAddress: address[0],
                city: address[1],
                zipCode: Number(address[2]) | 0,
                siret: response.data.companySiret,
                email: response.data.email,
                maxNbOfTours: response.data.maxTours,
                secretCode: response.data.secretCodeIPad
            }
        }))
    });
  }

  async submitForm(event) {
    event.preventDefault();

    this.updateProfile();
  }

  async updateProfile() {
    console.log("new state : ", this.state)
    if (!this.state.account.firstName || this.state.account.firstName == "" ||
        !this.state.account.lastName || this.state.account.lastName == "" ||
        !this.state.account.company || this.state.account.company == "" ||
        !this.state.account.siret || this.state.account.siret == "" ||
        !this.state.account.phoneNumber || this.state.account.phoneNumber == "" ||
        !this.state.account.streetAddress || this.state.account.streetAddress == "" ||
        !this.state.account.city || this.state.account.city == "" ||
        this.state.account.zipCode == -1) {
            this.setState(prevState => ({
                ...prevState,
                showError: true,
                errorMessage: "Veuillez remplire tout les champs",
                showSuccess: false,
                dialogWaringOpened: false,
            }));

            return;
    }

    console.log("state: ", this.state)

    try {
        validateAccount(
          "parameternotsent@gmail.com",
          this.state.account.siret,
          this.state.account.phoneNumber,
          1
         )
    } catch (err) {
        console.log(err.message)
        this.setState(prevState => ({
            ...prevState,
            showError: true,
            errorMessage: err.message,
            showSuccess: false,
            dialogWaringOpened: false,
        }));

        return
    }

    let addr = this.state.account.streetAddress + ";" + this.state.account.city + ";" + this.state.account.zipCode
    let response = await ApiUser.updateAccount(
        this.state.account.firstName,
        this.state.account.lastName,
        this.state.account.company,
        addr,
        this.state.account.siret,
        this.state.account.phoneNumber
    )

    if (response.status == 200 && response.data) {
        this.setState(prevState => ({
            ...prevState,
            showError: false,
            showSuccess: true,
            successMessage: "Le compte à bien été mis à jour",
            dialogWaringOpened: false,
        }));
    } else {
        this.setState(prevState => ({
            ...prevState,
            showError: true,
            errorMessage: "Une érreur est survenu",
            showSuccess: false,
            dialogWaringOpened: false,
        }));
    }
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin()

    if (response.status == 200) {
      this.setState(prevState => ({
        ...prevState,
        isAdmin: response.data.isAdmin
      }));  
    }
  }

  updateForm(event) {
    let target = event.target;
    let value = target.value;

    if (target.name == "zipCode") {
      value = Number(target.value);
    }

    this.setState(prevState => ({
        ...prevState,
        account: {
            ...prevState.account,
            [target.name]: value
        }
    }))
  }

  render() {
    const { classes } = this.props;
    
    return (
      <>
        <Authenticated />
        <MainAppBar headerText="Mon compte" showHomeButton={true} showAdminButton={this.state.isAdmin}/>

        <Container className={classes.formContainer} maxWidth="md">
          <form className={classes.form} autoComplete="off" onSubmit={this.submitForm}>
            <TextField
            required
            name="firstName"
            id="filled-required"
            defaultValue={this.state.account.firstName}
            label="Prénom"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'firstNameNotLoaded' : 'firstNameLoaded'}
            />
            <TextField
            required
            name="lastName"
            id="filled-required"
            defaultValue={this.state.account.lastName}
            label="Nom"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'lastNameNotLoaded' : 'lastNameLoaded'}
            />
            <br />
            <TextField
            disabled
            id="outlined-disabled"
            defaultValue={this.state.account.email}
            label="Email"
            variant="filled"
            className={classes.textField}
            key={this.state.accountLoaded ? 'emailNotLoaded' : 'emailLoaded'}
            />
            <TextField
            required
            name="phoneNumber"
            id="filled-required"
            defaultValue={this.state.account.phoneNumber}
            label="Numéro de téléphone"
            variant="filled"
            type="tel"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'phoneNumberNotLoaded' : 'phoneNumberLoaded'}
            />
            <br/>
            <Link className={classes.passwordForgotten} to="/request-password-reset">
                Changer de mot de passe
            </Link>
            <br/><br/><Divider/><br/>
            <TextField
            required
            name="company"
            id="filled-required"
            defaultValue={this.state.account.company}
            label="Entreprise"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 35 }}
            key={this.state.accountLoaded ? 'companyNotLoaded' : 'companyNameLoaded'}
            />
            <TextField
            required
            name="siret"
            id="filled-required"
            defaultValue={this.state.account.siret}
            label="Numéro Siret"
            variant="filled"
            type="number"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'siretNameNotLoaded' : 'siretNameLoaded'}
            />
            <br />
            <TextField
            required
            name="streetAddress"
            id="filled-required"
            defaultValue={this.state.account.streetAddress}
            label="Rue"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'streetAddressNotLoaded' : 'streetAddressNameLoaded'}
            />
            <TextField
            required
            name="city"
            id="filled-required"
            defaultValue={this.state.account.city}
            label="Ville"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'cityNameNotLoaded' : 'cityNameLoaded'}
            />
            <TextField
            required
            name="zipCode"
            id="filled-required"
            defaultValue={this.state.account.zipCode}
            label="Code postal"
            variant="filled"
            type="number"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'zipCodeNotLoaded' : 'zipCodeLoaded'}
            />
            <br/><br/><Divider/><br/>
            <TextField
            disabled
            id="outlined-disabled"
            defaultValue={this.state.account.maxNbOfTours}
            label="Nombre max de parcours"
            type="number"
            variant="filled"
            className={classes.textField}
            key={this.state.accountLoaded ? 'nbMaxToursNotLoaded' : 'nbMaxToursLoaded'}
            />
            <TextField
            disabled
            id="outlined-disabled"
            defaultValue={this.state.account.secretCode}
            label="Code secret"
            helperText="Il s'agit du code que vous devez rentrer dans vos IPads pour pouvoir récupérer vos parcours"
            variant="filled"
            className={classes.textField}
            key={this.state.accountLoaded ? 'secretCodeNotLoaded' : 'secretCodeLoaded'}
            />
            <br/><br/><Divider/><br/>
            <Select
              labelId="select-month-offset"
              id="select-month-offset"
              value={this.state.monthOffset ?? 999}
              onChange={this.updateMonthOffset}
            >
              <MenuItem value={999}>Tout</MenuItem>
              <MenuItem value={3}>3 mois</MenuItem>
              <MenuItem value={6}>6 mois</MenuItem>
              <MenuItem value={12}>1 an</MenuItem>
            </Select>
            <BarChart chartData={this.state.analytics} />
            { this.state.showError ? (
                <Alert severity="error">{this.state.errorMessage}</Alert>
                ): (<div />) }
            { this.state.showSuccess ? (
                <Alert severity="success">{this.state.successMessage}</Alert>
                ): (<div />) }
            <br/>
            <Button variant="outlined" color="primary" type="submit">
              ENREGISTRER
            </Button>
          </form>
        </Container>
      </>
    )
  }

}

export default withStyles(styles, { withTheme: true })(Account);