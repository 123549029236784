import * as React from 'react';
import DraggableListItem from './draggableListItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';


const DraggableList = React.memo(({ items, onDragEnd, onDelete, onDuplicate }) => {

  if (!items) {
    
    return <div>Items: {items}</div>;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableListItem item={item} index={index} key={item.id} onDelete={onDelete} onDuplicate={onDuplicate} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;
