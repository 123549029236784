import AuthStorage from "./authStorage"
import ApiUser from "../API/ApiUser"

export default class JwtUtils {

    static getValidTokens = async () => {
        try {
            let accessToken = localStorage.getItem('accessToken')
            let refreshToken = localStorage.getItem('refreshToken')
            let accessTokenExp = localStorage.getItem('accessTokenExp')

            console.log("Get tokens from localStorage: ", { accessToken: accessToken, refreshToken: refreshToken, accessTokenExp: accessTokenExp })

            if (!accessToken || !refreshToken || !accessTokenExp) {
                console.log("GET_VALID_TOKENS one token null", { accessToken: accessToken, refreshToken: refreshToken, accessTokenExp: accessTokenExp })

                return null
            }

            var date = new Date();

            if (date.getTime() >= parseInt(accessTokenExp) * 1000) {

                console.log("GET_VALID_TOKENS refreshTokens", refreshToken)

                let response = await ApiUser.refreshToken(refreshToken);
                if (response.status == 200) {

                    console.log("GET_VALID_TOKENS response data : ", response.data)

                    AuthStorage.saveTokens(
                        response.data.accessToken,
                        response.data.refreshToken,
                        response.data.accessTokenExp
                    )

                    return {
                        accessToken: response.data.accessToken,
                        refreshToken: response.data.refreshToken,
                        accessTokenExp: response.data.accessTokenExp
                    };
                } else {

                    console.log("GET_VALID_TOKENS response : ", response)

                return null
                }
              
            } else {

                return {
                    accessToken: accessToken,
                    refreshToken: refreshToken,
                    accessTokenExp: accessTokenExp
                };
             }
        
        }
        catch (error) {
            console.log(error.message)

            return null
        }



    }

}
