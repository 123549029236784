import React from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar,  Typography, Button, Slider, Divider, Fab, Select, FormControl, InputLabel, MenuItem, Card, CardMedia, IconButton } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Container from '@material-ui/core/Container';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
import qs from 'qs';
import Alert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid } from '@material-ui/core';
import ApiUser from '../../API/ApiUser';

import ImageUtils from '../../utils/imageUtils';
import ApiEnigma from '../../API/ApiEnigma';
import Authenticated from '../components/authenticated';
import MainAppBar from "../components/MainAppBar";
import LoadingView from '../components/loadingView';
import { getSizeInMb } from "../../utils/sizeInMb";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  formContainer: {
    paddingTop: '30px',
    paddingBottom: '30px'
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '20ch'
    }
  },
  imageCardVintage: {
    marginLeft: 10,
    width: 1092 / 4,
    height: 825 / 4,
    marginTop: 20,
  },
  imageCardClue: {
    marginLeft: 10,
    width: 1152 / 4,
    height: 1308 / 4,
    marginTop: 20,
  },
  imageCardMedia: {
    width: "100%",
    height: "100%"
  },
  uploadFile: {
    marginTop: 10,
    marginLeft: 10,
    maxWidth: 300
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  enigmasButtons: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textfieldResponses: {
    minWidth: 350
  },
  textFieldBig: {
    minWidth: 400
  },
  returnButtonLink : {
    textDecoration: 'none',
    margin: theme.spacing(2)
  },
  imageDimensionsText: {
    paddingLeft: 8,
    color: "#494949ff",
    fontSize: 12
  }
});

class NewEnigma extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isAdmin: false,
            enigmaLoaded: false,
            editing: false,
            showError: false,
            errorMessage: "",
            showSuccess: false,
            successMessage: "", 
            dialogWaringOpened: false,
            loadingViewOpened: false,
            showVintageImageTooBig: false,
            showClueImageTooBig: false,
            tourIdToDelete: -1,           
            formDatas: {
              tourId: null,
              zoneId: null,
              enigmaId: null,  
              responseType: "text",
              responsesPicker: [""],
              responsesTextStr: "",
              responsesPickerStr: "",
              pickerChoices: [],
              picker: null,  
              title: "",
              finishText: "",
              showFinishTextOnLoose: true,
              clue: {
                title: "",
                timer: 2,
                imageFile: null,
                imageUrl: null,
                imageDeleted: false
              },
              vintage: {
                title: "",
                imageFile: null,
                imageUrl: null,
                imageDeleted: false
              }  
            }
        }

        this.submitForm = this.submitForm.bind(this);

        this.vintageImageSelected = this.vintageImageSelected.bind(this);
        this.clueImageSelected = this.clueImageSelected.bind(this);
        this.handleSelectResponseTypeChange = this.handleSelectResponseTypeChange.bind(this);
        this.handleResponsesPickerTextChange = this.handleResponsesPickerTextChange.bind(this);
        this.getPickerChoiceValue = this.getPickerChoiceValue.bind(this);
        this.updatePickerChoice = this.updatePickerChoice.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.updateResponsesText = this.updateResponsesText.bind(this);
        this.updateClueTitle = this.updateClueTitle.bind(this);
        this.updateClueTime = this.updateClueTime.bind(this);
        this.updateVintageTitle = this.updateVintageTitle.bind(this);
        this.getPickerChoiceValue = this.getPickerChoiceValue.bind(this);
        this.updateFinishText = this.updateFinishText.bind(this);
        this.updateShowFinishOnLoose = this.updateShowFinishOnLoose.bind(this);

        this.deleteImage = this.deleteImage.bind(this);
    }

    componentDidMount() {
      document.title = "Énigme | Landing Zone"

      let tourId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).tourId;
      let zoneId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).zoneId;
      let enigmaId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).enigmaId;
  
      let tourIdInt = parseInt(tourId);
      let zoneIdInt = parseInt(zoneId);
      let enigmaIdInt = parseInt(enigmaId);
  
      if (!tourIdInt || !zoneIdInt) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Une erreur est survenue. Impossible de trouver les données liées à cette zone",
          showSuccess: false,
        }));

        return;
      }

      this.setState(prevState => ({
        ...prevState,
        formDatas: {
          ...prevState.formDatas,
          tourId: tourIdInt,
          zoneId: zoneIdInt 
        }
      }));

      if (!enigmaIdInt)
        return;
  
      this.setStateIsAdmin()

      ApiEnigma.getEnigma(enigmaId).then((enigmaDatas) => {
        if (!enigmaDatas || enigmaDatas.status !== 200) {
          this.setState(prevState => ({
            ...prevState,
            showError: true,
            errorMessage: "Une erreur est survenue lors de la récupération du circuit",
            showSuccess: false,
          }));
    
          return;
        }
  
        this.setForm(enigmaDatas.data);
      });
    }

    async setStateIsAdmin() {
      let response = await ApiUser.isAdmin()
  
      if (response.status == 200) {
        this.setState(prevState => ({
          ...prevState,
          isAdmin: response.data.isAdmin
        }));  
      }
    }

    setForm(enigmaDatas) {

      let pickerChoices = [];

      enigmaDatas.picker.forEach( (choice) => {
        pickerChoices.push(JSON.parse(choice));
      })

      this.setState( prevState => ({
        ...prevState,
        editing: true,
        enigmaLoaded: true,
        formDatas: {
          ...prevState.formDatas,
          enigmaId: enigmaDatas.id,
          zoneId: enigmaDatas.ZoneId,  
          responseType: enigmaDatas.responseType,
          responsesPicker: enigmaDatas.responseType == "picker" ? enigmaDatas.responses.split(';') : [""],
          responsesTextStr: enigmaDatas.responseType == "text" ? enigmaDatas.responses : "",
          responsesPickerStr: enigmaDatas.responseType == "picker" ? enigmaDatas.responses : "",
          pickerChoices: pickerChoices,
          picker: null, // A CHANGER  
          title: enigmaDatas.title,
          finishText: enigmaDatas.finishText,
          showFinishTextOnLoose: enigmaDatas.showFinishTextOnLoose,
          clue: {
            title: enigmaDatas.clueTitle,
            timer: enigmaDatas.clueTimer,
            imageFile: null,
            imageUrl: enigmaDatas.clueImageUrl
          },
          vintage: {
            title: enigmaDatas.vintageTitle,
            imageFile: null,
            imageUrl: enigmaDatas.vintageImageUrl
          }
        }
      }));
    }

    getPickerChoiceValue(event, i) {
      event.preventDefault();


      if (this.state.formDatas.pickerChoices.lenght > i)
        return this.state.formDatas.pickerChoices[i].value
      
        return ""
    }

    updateFinishText(event) {
      event.preventDefault();

      this.setState( prevState => ({
        formDatas: {
          ...prevState.formDatas,
          finishText: event.target.value
        }
      }));
    }
    updateShowFinishOnLoose(event) {
      event.preventDefault();

      this.setState( prevState => ({
        formDatas: {
          ...prevState.formDatas,
          showFinishTextOnLoose: event.target.value
        }
      }));
    }

    updateVintageTitle(event) {
      event.preventDefault();

      this.setState( prevState => ({
        formDatas: {
          ...prevState.formDatas,
          vintage: {
            ...prevState.formDatas.vintage,
            title: event.target.value
          }
        }
      }));
    }

    updateTitle(event) {
      event.preventDefault();

      this.setState( prevState => ({
        formDatas: {
          ...prevState.formDatas,
          title: event.target.value
        }
      }));
    }

    updateClueTitle(event) {
      event.preventDefault();

      this.setState( prevState => ({
        formDatas: {
          ...prevState.formDatas,
          clue: {
            ...prevState.formDatas.clue,
            title: event.target.value
          }
        }
      }));
    }

    updateClueTime(event, value) {
      event.preventDefault();

      this.setState( prevState => ({
        formDatas: {
          ...prevState.formDatas,
          clue: {
            ...prevState.formDatas.clue,
            timer: parseInt(value)
          }
        }
      }));
    }

    updatePickerChoice(event) {
      event.preventDefault();

      var choices = this.state.formDatas.pickerChoices;
      var choiceExist = false;

      if (this.state.formDatas.pickerChoices) {

        this.state.formDatas.pickerChoices.forEach( (choice, i) => {
          if (choice.id == event.target.id) {
            choices[i].value = event.target.value;
            choiceExist = true;
          } 
        });  
        if (!choiceExist)
          choices.push({ id: event.target.id, value: event.target.value });
      } else {
        choices = [{ id: event.target.id, value: event.target.value }];
      }

      this.setState( prevState => ({
        formDatas: {
          ...prevState.formDatas,
          pickerChoices: choices
        }
      }));
    }

    updateResponsesText(event) {
      event.preventDefault();

      this.setState( prevState => ({
        formDatas: {
          ...prevState.formDatas,
          responsesTextStr: event.target.value
        }
      }));
    }

    handleResponsesPickerTextChange(event) {
      if (event.target == null) {
        return;
      }


      let responsesTab = event.target.value.split(";");

      if (responsesTab == null)
        return;
      var responses = [];

      responsesTab.forEach(elem => {
        responses.push(elem);
      });

      this.setState( prevState => ({
        formDatas: {
          ...prevState.formDatas,
          responsesPicker: responses,
          responsesPickerStr: event.target.value  
        }
      }), () => {
        var newChoices = this.state.formDatas.pickerChoices;
        if (this.state.formDatas.pickerChoices
            && this.state.formDatas.pickerChoices.length > this.state.formDatas.responsesPicker.length) {
          newChoices.pop();
          this.setState( prevState => ({
            formDatas: {
              ...prevState.formDatas,
              pickerChoices: newChoices
            }
          }));
          }
      });
    }
  
    vintageImageSelected(event) {
      event.preventDefault();

      let fileSizeMb = getSizeInMb(event.target.files[0].size)

      if (fileSizeMb > 1) {
        this.setState( prevState => ({
            ...prevState,
            showVintageImageTooBig: true
          }));
  
        return;
      }

      this.setState( prevState => ({
        ...prevState,
        showVintageImageTooBig: false,
        formDatas: {
          ...prevState.formDatas,
          vintage: {
            ...prevState.formDatas.vintage,
            imageFile: event.target.files[0]
          }
        }
      }));
    }
  
    clueImageSelected(event) {
      event.preventDefault();

      let fileSizeMb = getSizeInMb(event.target.files[0].size)

      if (fileSizeMb > 1) {
        this.setState( prevState => ({
            ...prevState,
            showClueImageTooBig: true
          }));
  
        return;
      }

      this.setState( prevState => ({
        ...prevState,
        showClueImageTooBig: false,
        formDatas: {
          ...prevState.formDatas,
          clue: {
            ...prevState.formDatas.clue,
            imageFile: event.target.files[0]
          }
        }
      }));
    }


  handleSelectResponseTypeChange(event, value) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        responseType: event.target.value
      }
    }));
  }

  deleteImage(deleteClueImage, deleteVintageImage) {
    if (deleteClueImage) {
      this.setState( prevState => ({
        ...prevState,
        formDatas: {
          ...prevState.formDatas,
          clue: {
            ...prevState.formDatas.clue,
            imageFile: null,
            imageUrl: null,
            imageDeleted: true
          }
        }
      }));
    }
    if (deleteVintageImage) {
      this.setState( prevState => ({
        ...prevState,
        formDatas: {
          ...prevState.formDatas,
          vintage: {
            ...prevState.formDatas.vintage,
            imageFile: null,
            imageUrl: null,
            imageDeleted: true
          }
        }
      }));
    }
  }

  async submitForm(event) {
    event.preventDefault();

    var serverResp = null;

    this.setState(prevState => ({
      ...prevState,
      loadingViewOpened: true
    }));
    if (this.state.editing) {
      serverResp = await ApiEnigma.updateEnigma(this.state.formDatas, this.state.formDatas.enigmaId);
    }
    else {
      serverResp = await ApiEnigma.uploadEnigma(this.state.formDatas);
    }

    this.setState(prevState => ({
      ...prevState,
      loadingViewOpened: false
    }));
    if (serverResp.status != 201 && serverResp.status != 200) {
      this.setState(prevState => ({
        ...prevState,
        showError: true,
        errorMessage: `Une érreur est survenue lors de la communication avec le serveur`,
        showSuccess: false,
      }));
    
      return;
    } else {
        this.setState(prevState => ({
        ...prevState,
        showSuccess: true,
        editing: true,
        showError: false,
        errorMessage: "",
        successMessage: "L'énigme à bien été sauvegardé",
        editing: true,
        formDatas: {
          ...prevState.formDatas,
          enigmaId: serverResp.data.enigmaId
        }
      }));
    }
  }

  render() {
    const { classes } = this.props;

    return (
    <>
      <Authenticated />
      <MainAppBar headerText="Énigme" showHomeButton={true} showAdminButton={this.state.isAdmin}/>
      <Container className={classes.formContainer} maxWidth="md">
      <form className={classes.form} autoComplete="off" onSubmit={this.submitForm}>
        <TextField
          required
          id="filled-required"
          label="Texte de l'énigme"
          variant="filled"
          defaultValue={this.state.formDatas.title}
          onChange={this.updateTitle}
          className={classes.textFieldBig}
          multiline={true}
          inputProps={{ maxLength: 400 }}
          key={this.state.enigmaLoaded ? 'titleNotLoaded' : 'titleLoaded'}
        />
        <br/><br/><Divider/><br/>

        <FormControl className={classes.formControl}>
        <InputLabel id="select-response-type">Réponse</InputLabel>
        <Select
          labelId="select-response-type"
          id="select-response-type"
          value={this.state.formDatas.responseType}
          onChange={this.handleSelectResponseTypeChange}
        >
          <MenuItem value={"text"}>Texte</MenuItem>
          <MenuItem value={"picker"}>Choix multiple</MenuItem>
        </Select>
        </FormControl>


        { this.state.formDatas.responseType == "text" ? (
            <TextField
                required
                id="standard-helperText"
                label="Réponse(s)"
                helperText="Il est possible de rentrer plusieurs réponses possibles. Pour cela séparez chaque réponse d'une ';' (ex: blockhaus;blockos;blokausse)"
                variant="filled"
                className={classes.textfieldResponses}
                defaultValue={this.state.formDatas.responsesTextStr}
                onChange={this.updateResponsesText}
                key={this.state.enigmaLoaded ? 'responsesNotLoaded' : 'responsesLoaded'}      
            />
        ) : (
            <TextField
                required
                id="standard-helperText"
                label="Réponse à choix multiple"
                helperText="Veuillez séparer chaque réponse d'un ';' (ex: ceci;est;la;réponse)"
                variant="filled"
                className={classes.textfieldResponses}
                defaultValue={this.state.formDatas.responsesPickerStr}
                onChange={this.handleResponsesPickerTextChange}
                key={this.state.enigmaLoaded ? 'choicesRespnonsesNotLoaded' : 'choicesResponsesLoaded'}      
            />
        )}
        <br/><br/>

        { this.state.formDatas.responseType == "picker" ? (
          this.state.formDatas.responsesPicker.map((response, index) => {
            let pickerId = `picker-response-${index.toString()}`
            let pikerLabel = `Propositions pour la réponse ${response}`

            return (
              <TextField
                required
                id={pickerId}
                label={pikerLabel}
                helperText="Veuillez séparer chaque proposition d'un ';' (ex: ceci;cela;ce)"
                variant="filled"
                className={classes.textfieldResponses}
                onChange={ this.updatePickerChoice }
                defaultValue={this.state.formDatas.pickerChoices ?
                  (this.state.formDatas.pickerChoices.length > index ? this.state.formDatas.pickerChoices[index].value : "")
                  : "" }
                key={this.state.enigmaLoaded ? `${pickerId}NotLoaded` : `${pickerId}e$enigmaLoaded`}      
              />
            )
          })
        ) : (<br/>)}

        <br/><br/><Divider/><br/>
        <Typography variant="h6" gutterBottom>
            (optionnel)
        </Typography>

        <TextField
            id="filled-required"
            label="Texte à afficher à la fin de l'énigme"
            inputProps={{ maxLength: 255 }}
            variant="filled"
            defaultValue={this.state.formDatas.finishText}
            className={classes.textfieldResponses}
            onChange={this.updateFinishText}
            key={this.state.enigmaLoaded ? 'finishTextLoaded' : 'finishTextNotLoaded'}
        />

        <FormControl className={classes.formControl}>
          <InputLabel id="select-response-type">Affichage du texte</InputLabel>
          <Select
            labelId="select-response-type"
            id="select-response-type"
            value={this.state.formDatas.showFinishTextOnLoose}
            onChange={this.updateShowFinishOnLoose}
          >
            <MenuItem value={true}>En cas de bonne et de mauvaise réponse</MenuItem>
            <MenuItem value={false}>Uniquement en cas de bonne réponse</MenuItem>
          </Select>
        </FormControl>


        <br/><br/><Divider/><br/>

        <Typography variant="h6" gutterBottom>
            (optionnel)
        </Typography>

        <TextField
            id="filled-required"
            label="Texte de l'indice"
            inputProps={{ maxLength: 60 }}
            variant="filled"
            defaultValue={this.state.formDatas.clue.title}
            className={classes.textfieldResponses}
            onChange={this.updateClueTitle}
            key={this.state.enigmaLoaded ? 'clueTitleLoaded' : 'clueTitleNotLoaded'}
        />

        <br/>

        <Button
            variant="contained"
            color="secondary"
            className={classes.uploadFile}
            startIcon={<CloudUploadIcon />}
            component="label"
        >
            Image de l'indice
            <input
                type="file"
                onChange={this.clueImageSelected}
                hidden
                accept=".png,.jpg,.jpeg"
            />
        </Button>
        <IconButton edge="end" aria-label="delete" onClick={ (e) => { this.deleteImage(true, false) } }>
          <DeleteIcon style={{ color: 'red' }}/> 
        </IconButton>
        <Typography className={classes.imageDimensionsText} align='inherit' gutterBottom>
          Taille maximum: 1mo
        </Typography>
        <Typography className={classes.imageDimensionsText} gutterBottom>
          Dimensions recommandées: 1152x1308px
        </Typography>
        { this.state.showClueImageTooBig ? (
          <Typography className={classes.imageDimensionsText} style={{ color: 'red' }} gutterBottom>
          L'image ne doit pas éxcéder 1mo
          </Typography>
        ): (<div />) }
        <Card className={classes.imageCardClue}>
            <CardMedia
                className={classes.imageCardMedia}
                image=
                  {
                    ImageUtils.getImageUrlFromFile(this.state.formDatas.clue.imageFile)
                    ? ImageUtils.getImageUrlFromFile(this.state.formDatas.clue.imageFile)
                    : ImageUtils.getImageUrlFromUri(this.state.formDatas.clue.imageUrl)
                  }
                title="ClueImage"
            />
        </Card>

        <br/>
        <Typography id="discrete-slider" gutterBottom>
          Temps avant que l'indice soit disponnible
        </Typography>
        <Slider
          onChange={this.updateClueTime}
          value={this.state.formDatas.clue.timer}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={0}
          max={30}
          key={this.state.enigmaLoaded ? 'timerClueNotLoaded' : 'timerClueLoaded'}
        />

        <br/><br/><Divider/><br/>

        <Typography variant="h6" gutterBottom>
            (optionnel)
        </Typography>

        <TextField
            id="filled"
            label="Titre de la photo de l'énigme"
            variant="filled"
            inputProps={{ maxLength: 40 }}
            defaultValue={this.state.formDatas.vintage.title}
            onChange={this.updateVintageTitle}
            key={this.state.enigmaLoaded ? 'vintageTitleLoaded' : 'vintageTitleNotLoaded'}
        />

        <br/>

        <Button
            variant="contained"
            color="secondary"
            className={classes.uploadFile}
            startIcon={<CloudUploadIcon />}
            component="label"
        >
            Photo de l'énigme
            <input
                type="file"
                onChange={this.vintageImageSelected}
                hidden
                accept=".png,.jpg,.jpeg"
            />
        </Button>
        <IconButton edge="end" aria-label="delete" onClick={ (e) => { this.deleteImage(false, true) } }>
          <DeleteIcon style={{ color: 'red' }}/> 
        </IconButton>
        <Typography className={classes.imageDimensionsText} align='inherit' gutterBottom>
          Taille maximum: 1mo
        </Typography>
        <Typography className={classes.imageDimensionsText} gutterBottom>
          Dimensions recommandées: 1092x825px
        </Typography>
        { this.state.showVintageImageTooBig ? (
          <Typography className={classes.imageDimensionsText} style={{ color: 'red' }} gutterBottom>
          L'image ne doit pas éxcéder 1mo
          </Typography>
        ): (<div />) }
        <Card className={classes.imageCardVintage}>
            <CardMedia
                className={classes.imageCardMedia}
                image=
                {
                  ImageUtils.getImageUrlFromFile(this.state.formDatas.vintage.imageFile)
                  ? ImageUtils.getImageUrlFromFile(this.state.formDatas.vintage.imageFile)
                  : ImageUtils.getImageUrlFromUri(this.state.formDatas.vintage.imageUrl)
                }
                title="VintageImage"
            />
        </Card>

        <br/><br/><Divider/><br/>
        { this.state.showError ? (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        ): (<div />) }
        { this.state.showSuccess ? (
          <Alert severity="success">{this.state.successMessage}</Alert>
        ): (<div />) }
        <br/>
        <LoadingView
          className={classes.loadingView}
          open={this.state.loadingViewOpened}
        />

      <Button variant="outlined" color="primary" type="submit">
        ENREGISTRER
      </Button>
      <Link className={classes.returnButtonLink} to={`/new-zone?tourId=${this.state.formDatas.tourId}&zoneId=${this.state.formDatas.zoneId}`}>
        <Button variant="outlined" color="darkGrey" >
          RETOUR
        </Button>
      </Link>
      </form>
      </Container>
      </>
    )
  }

}

export default withStyles(styles, { withTheme: true })(NewEnigma);