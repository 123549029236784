export default class BarChartUtils {

    static formatDatasChart = async (datas, names, minusMonth) => {

        var dateOptions = {
          year: "numeric",
          month: "2-digit"
        };

        var minDate = null;
        if (minusMonth && minusMonth != 999) {
            minDate = new Date();
            minDate.setMonth(minDate.getMonth() - minusMonth);
        }

    
        var dataFormatted = { datasets: [] }
    
        let backgroundColors = [ 'rgb(174, 174, 203)', 'rgb(255, 220, 123)', 'rgb(111, 154, 178)', 'rgb(230, 117, 86)', 'rgb(146, 227, 134)', 'rgb(228, 227, 134)', 'rgb(144, 170, 74)', 'rgb(218, 170, 178)', 'rgb(172, 170, 178)', 'rgb(225, 255, 178)', 'rgb(204, 195, 190)', 'rgb(54, 195, 190)' ]
    
        for (var i in names) {
          let backgroundColor = 'rgba(255, 91, 132, 0.2)'
          if (backgroundColors.length > i) {
            backgroundColor = backgroundColors[i];
          }
          dataFormatted.datasets.push({ label: names[i], data: [], backgroundColor: [ backgroundColor ] })
        }
    
        for (var i in datas) { // Sessions
          for (var j in dataFormatted.datasets) { // Datas stored by Tours
            if (dataFormatted.datasets[j].label == datas[i].tourName) {
              let date = new Date(datas[i].createdAt).toLocaleDateString("fr", dateOptions);
              let dateCompare = new Date(datas[i].createdAt)
              let sameDateFound = false;
              if (minDate && dateCompare.getTime() < minDate.getTime()) {

                console.log("Date ", dateCompare.getTime(), "is less than ", minDate.getTime())

                continue;
              } else {
                console.log("Date ", date, "is not less than ", minDate)
   
              }
              
              for (var k in dataFormatted.datasets[j].data) {
                if (dataFormatted.datasets[j].data[k].x == date) {
                  dataFormatted.datasets[j].data[k].y += 1;
                  sameDateFound = true;
                }
              }
              if (!sameDateFound) {
                dataFormatted.datasets[j].data.push({ x: date, y: 1 })
              }
    
            }
          }
        }
    
        return dataFormatted
      }
}


