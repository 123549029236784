import React from 'react';
import { Redirect } from "react-router-dom";

import JwtUtils from '../../utils/jwtUtils';

class Authenticated extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tokens: null,
      tokensSet: false
    }
  }

  componentDidMount() {
    this.getTokens()
  }

  async getTokens() {
    const tokens = await JwtUtils.getValidTokens();

    console.log("AUTHENTICATED getValidTokens res: ", tokens)

    this.setState(prevState => ({
      ...prevState,
      tokens: tokens,
      tokensSet: true
    }));
  }

  render() {
    console.log("Tokens in authenticated = ", this.state.tokens)
    console.log("TokensSet in authenticated = ", this.state.tokensSet)

    return (
      <>
      { !this.state.tokens && this.state.tokensSet ? (
          <Redirect
              to={{
                pathname: "/login"
              }}
          />
      ) : (<div/>)
      }
      </>
    );
  }
};

export default Authenticated;
