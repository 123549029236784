import { Bar } from "react-chartjs-2";
import 'chartjs-adapter-date-fns';

const options = {
    plugins: {
        title: {
            display: true,
            text: 'Sessions sur Ipad',
            padding: {
                top: 10,
                bottom: 30
            }
        }
    },
    scales: {
        xAxes:
        {
            type: "time",
            time: {
                parser: "MM/yyyy",
                tooltipFormat:'MM/yyyy',
                unit: 'month'
            },
            scaleLabel: {
                display:     true,
                labelString: 'Date'
            }
        },
        yAxes:
        {
            scaleLabel: {
                display:     true,
                labelString: 'Sessions'
            }
        }
    }
};

export const BarChart = ({ chartData }) => {
  return (
    <div>
      <Bar
        data={chartData}
        options={options}
      />
    </div>
  );
};