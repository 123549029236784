import React, { Component } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import { Button } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import AuthStorage from '../../utils/authStorage';
import ApiUser from '../../API/ApiUser';
import Authenticated from '../components/authenticated';

const styles = (theme) => ({
  formContainer: {
    paddingTop: '30px',
    paddingBottom: '30px',
    width: '60ch',
    margin: 'auto'
  },
  textfield: {
    minWidth: 200,
    margin: theme.spacing(1)
  },
  validateForm: {
    textDecoration: 'none',
    marginTop: 20,
    marginBottom: 20,
    margin: theme.spacing(1)

  },
  passwordForgotten: {
    color: 'blue',
    margin: theme.spacing(1)
  }
});

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      islogged: false,
      loginParams: {
        user_email: "",
        user_password: ""
      }
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    document.title = "Connexion | Landing Zone"
  }

  handleFormChange(event) {
    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;

    this.setState({
      loginParams: loginParamsNew
    });
  };
 
  async login(event) {
    event.preventDefault();
    console.log("LOGIN VIEW login()")
    let user_email = this.state.loginParams.user_email;
    let user_password = this.state.loginParams.user_password;

    let response = await ApiUser.signin(user_email, user_password);

    console.log("LOGIN VIEW login response: ", response)

    if (response.status == 200) {
      console.log("LOGIN VIEW tokens ", AuthStorage.getTokens())
      AuthStorage.saveTokens(response.data.accessToken, response.data.refreshToken, response.data.accessTokenExp)
      this.setState({
        islogged: true
      });
    } else {
      console.log("INCORRECT")
      this.setState(prevState => ({
        ...prevState,
        errorMessage: "Mot de passe ou nom d'utilisateur invalide",
        showError: true
      }));
    }
  };

  render() {
    const { classes } = this.props;

    console.log("IN LOGIN")
    if (this.state.islogged) {

      return <Redirect to="/" />;
    }

    return (
     <div>
        <br/>
        { this.state.showError ? (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        ): (<div />) }
        { this.state.showSuccess ? (
          <Alert severity="success">{this.state.successMessage}</Alert>
        ): (<div />) }

        <form onSubmit={this.login} className={classes.formContainer}>
          <h1 >Connexion</h1>
          <div >
            <div>
              <TextField
                name="user_email"
                onChange={this.handleFormChange}
                label="Email"
                className={classes.textfield}
                />
                <br/>
              <TextField
                name="user_password"
                onChange={this.handleFormChange}
                label="Mot de passe"
                className={classes.textfield}
                type="password"
                />
                <br/>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.validateForm}
                >
                  Valider
              </Button>
              <br/>
              <Link className={classes.passwordForgotten} to="/request-password-reset">
                Mot de passe oublié ?
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Login));