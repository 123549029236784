import React, { useState } from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";

import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./pages/user/home";
import NewTour from "./pages/user/newTour";
import NewZone from "./pages/user/newZone";
import NewEnigma from "./pages/user/newEnigma";
import Login from "./pages/user/login";
import ManageAccounts from './pages/admin/manageAccounts';
import Account from './pages/user/account'
import SignupUser from './pages/admin/signupUser';
import ManageAccount from './pages/admin/manageAccount';
import ResetPassword from './pages/user/resetPassword';
import RequestResetPassword from './pages/user/requestResetPassword';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2c78ff",
    },
    secondary: {
      main: "#e8c635",
    },
    danger: {
      main: "#DF0F0F"
    },
    darkGrey: {
      main: "#1d1d1d"
    },
    grey: {
      main: "#DED8BD"
    },
    lightGrey: {
      main: "#CACED5"
    },
    background: {
      default: "#EEEEEE"
    }
  },
});

const listStyle = {
  listStyle: "none",
};

const listItemStyle = {
  display: "inline",
  paddingRight: 20,
};

const pages = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/new-tour",
    name: "NewTour",
    component: NewTour,
  },
  {
    path: "/new-zone",
    name: "NewZone",
    component: NewZone,
  },
  {
    path: "/new-enigma",
    name: "newEnigma",
    component: NewEnigma,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/admin/manage-accounts",
    name: "manageAccounts",
    component: ManageAccounts,
  },
  {
    path: "/admin/manage-account",
    name: "manageAccounts",
    component: ManageAccount,
  },
  {
    path: "/admin/signup-user",
    name: "signupUser",
    component: SignupUser,
  },
  {
    path: "/user/account",
    name: "account",
    component: Account,
  },
  {
    path: "/request-password-reset",
    name: "account",
    component: RequestResetPassword,
  },
  {
    path: "/reset-password",
    name: "account",
    component: ResetPassword,
  }
]

const AppRouter = () => {

  return (
    <Router>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <div className="page-wrapper">
          {pages.map(page => (
            <Route
              key={page.path}
              path={page.path}
              exact
              component={page.component}
            />
          ))}
        </div>
      </div>
      </ThemeProvider>
    </Router>
  )
};

export default AppRouter;