import Axios from 'axios';

import JwtUtils from '../utils/jwtUtils';
import { apiHost } from '../constants/ApiConstants';

export default class ApiZone {

    static async uploadZone(zoneDatas, tourId) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.post(
            `${apiHost}/newZone`,
                {
                    zoneDatas: zoneDatas,
                    tourId: tourId
                }, {
                    headers: {"Authorization": authHeader}
                }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async updateZone(zoneDatas, tourId, zoneId) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        const authHeader = 'Bearer ' + tokens.accessToken;

        console.log("Senging zone ", zoneDatas)

        return await Axios.post(
            `${apiHost}/updateZone`,
                {
                    zoneDatas: zoneDatas,
                    tourId: tourId,
                    zoneId: zoneId
                }, {
                    headers: {"Authorization": authHeader}
                }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async updateZonesOrdersIds(zonesDatas) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.post(
            `${apiHost}/updateZonesOrderIds`,
                {
                    zonesDatas: zonesDatas
                }, {
                    headers: {"Authorization": authHeader}
                }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async getZones(tourId) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.get(
            `${apiHost}/zones`, {
                params: {
                    tourId: tourId
                },
                headers: {"Authorization": authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;       
         })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async getZone(tourId, zoneId) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.get(
            `${apiHost}/zone`, {
                params: {
                    tourId: tourId,
                    zoneId: zoneId
                },
                headers: {"Authorization": authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async deleteZone(id) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.delete(
            `${apiHost}/zone`, {
                params: {
                    zoneId: id
                },
                headers: {"Authorization": authHeader}  
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

}