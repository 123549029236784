import Axios from 'axios';

import { apiHost } from '../constants/ApiConstants';
import AuthStorage from '../utils/authStorage';
import JwtUtils from '../utils/jwtUtils';

export default class ApiAdmin {

    static async signupClientAccount(
        firstName,
        lastName,
        email,
        company,
        address,
        siret,
        phoneNumber,
        role,
        maxNbOfTours
    ) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }

        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.post(
            `${apiHost}/admin/signup`, {
                firstName: firstName,
                lastName: lastName,
                email: email,
                company: company,
                address: address,
                siret: siret,
                phoneNumber: phoneNumber,
                role: role,
                maxNbOfTours: maxNbOfTours
            },
            {
                headers: {'Authorization': authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = { status: error.response.status, data: error.response.data }

            return errorLog;
        });
    }

    static async getUsersAccounts() {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }

        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.get(
            `${apiHost}/admin/allAccounts/`, {
                headers: {'Authorization': authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = { status: error.response.status, data: error.response.data }

            return errorLog;
        });
    }

    static async getUserAccount(accountId) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }

        const authHeader = 'Bearer ' + tokens.accessToken;

        console.log("Route : ", `${apiHost}/admin/account/${accountId}`);

        return await Axios.get(
            `${apiHost}/admin/account/${accountId}`, {
                headers: {'Authorization': authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async deleteUserAccount(accountId) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }

        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.delete(
            `${apiHost}/admin/account/${accountId}`, {
                headers: {'Authorization': authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async getToursClient(accountId) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }

        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.get(
            `${apiHost}/admin/clientTours/${accountId}`, {
                headers: {'Authorization': authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async getAccountAnalytics(accountId) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }

        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.get(
            `${apiHost}/admin/allToursAnalytics/${accountId}`, {
                headers: {'Authorization': authHeader}
            }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }

    static async updateUserAccount(
        userId,
        firstName,
        lastName,
        company,
        address,
        siret,
        phoneNumber,
        maxNbOfTours
    ) {
        const tokens = await JwtUtils.getValidTokens()
        if (!tokens) {

            return { status: null, data: null }
        }
        
        const authHeader = 'Bearer ' + tokens.accessToken;

        return await Axios.post( `${apiHost}/admin/updateAccount/${userId}`, {
            firstName: firstName,
            lastName: lastName,
            company: company,
            address: address,
            siret: siret,
            phoneNumber: phoneNumber,
            maxNbOfTours: maxNbOfTours
        }, {
            headers: {"Authorization": authHeader}
        }
        ).then(response => {
            let responseLog = {status: response.status, data: response.data }

            return responseLog;
        })
        .catch(error => {
            let errorLog = {status: 500, data: "Undefined error"}
            if (error.response) {
                errorLog = { status: error.response.status, data: error.response.data }
            }

            return errorLog;
        });
    }
    
}