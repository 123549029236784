import React from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar,  Typography, Button, Slider, Divider, IconButton, colors, Box, InputLabel, Select, MenuItem } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
import qs from 'qs';
import Alert from '@material-ui/lab/Alert';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CategoryIcon from '@material-ui/icons/Category';
import validator from 'validator';

import ApiZone from '../../API/ApiZone';
import ApiEnigma from '../../API/ApiEnigma';
import ApiAdmin from "../../API/ApiAdmin";
import ApiUser from "../../API/ApiUser";
import DraggableList from "../components/draggableList";
import ListItemLink from '../components/listItemLink';
import DialogWarning from '../components/dialogWarning'
import Authenticated from '../components/authenticated';
import MainAppBar from "../components/MainAppBar";
import { validateAccount } from "../../utils/validateAccount";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  formContainer: {
    paddingTop: '30px',
    paddingBottom: '30px'
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '20ch'
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: 'none'
  },
  textField: {
    minWidth: 200
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderRadius: 5,
    listStyle: 'none'
  },
  buttonListPlus: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    color: 'white'
  },
  returnButtonLink : {
    textDecoration: 'none',
    margin: theme.spacing(2)
  },
  danger: {
    color: 'red'
  }
});

class SignupUser extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      isAdminSet: false,
      dialogWaringOpened: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      account : {
        firstName: null,
        lastName: null,
        email: null,
        company: null,
        streetAddress: null,
        city: null,
        zipCode: null,
        siret: null,
        phoneNumber: null,
        role: "EDITOR",
        maxNbOfTours: null
      }
    }

    this.submitForm = this.submitForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
  }


    componentDidMount() {
      document.title = "Nouveau compte | Landing Zone"

        this.setStateIsAdmin()
    }

    updateForm(event) {
        let target = event.target;

        this.setState(prevState => ({
            ...prevState,
            account: {
                ...prevState.account,
                [target.name]: target.value
            }
        }))
    }

    async setStateIsAdmin() {
        let response = await ApiUser.isAdmin()

        if (response.status == 200) {
        this.setState(prevState => ({
            ...prevState,
            isAdmin: response.data.isAdmin
        }));  
        }
    }

    async submitForm(event) {
      event.preventDefault();

      this.signup()
    }

    async signup() {
      console.log(1)
        if (!this.state.account.firstName ||
            !this.state.account.lastName ||
            !this.state.account.email ||
            !this.state.account.company ||
            !this.state.account.siret ||
            !this.state.account.phoneNumber ||
            !this.state.account.role ||
            !this.state.account.maxNbOfTours ||
            !this.state.account.streetAddress ||
            !this.state.account.city ||
            !this.state.account.zipCode) {
                this.setState(prevState => ({
                    ...prevState,
                    showError: true,
                    errorMessage: "Veuillez remplire tout les champs",
                    showSuccess: false,
                    dialogWaringOpened: false,
                }));

                return;
        }

        try {
          validateAccount(
            this.state.account.email,
            this.state.account.siret,
            this.state.account.phoneNumber,
            this.state.account.maxNbOfTours
           )
        } catch (err) {
          this.setState(prevState => ({
            ...prevState,
            showError: true,
            errorMessage: err.message,
            showSuccess: false,
            dialogWaringOpened: false,
          }));

          return
        }
        console.log(3)
        
        let maxToursInt = Number(this.state.account.maxNbOfTours)
        let addr = this.state.account.streetAddress + ";" + this.state.account.city + ";" + this.state.account.zipCode
        let response = await ApiAdmin.signupClientAccount(
            this.state.account.firstName,
            this.state.account.lastName,
            this.state.account.email,
            this.state.account.company,
            addr,
            this.state.account.siret,
            this.state.account.phoneNumber,
            this.state.account.role,
            maxToursInt
        )

        if (response.status == 201 && response.data) {
          console.log(4)
            this.setState(prevState => ({
                ...prevState,
                showError: false,
                showSuccess: true,
                successMessage: "Le compte à bien été créer. Un email contenant le mot de passe à été envoyé à " + this.state.account.email,
                dialogWaringOpened: false,
            }));
        }
        // else if (response.status == 409) {
        //   this.setState(prevState => ({
        //     ...prevState,
        //     showError: true,
        //     errorMessage: "Cette email existe déjà",
        //     showSuccess: false,
        //     dialogWaringOpened: false,
        //   }));
        // } 
        else {
          console.log(5)
            this.setState(prevState => ({
                ...prevState,
                showError: true,
                errorMessage: "Cette email existe déjà",
                showSuccess: false,
                dialogWaringOpened: false,
            }));
        }
    }

    openDialog() {
        this.setState(prevState => ({
            ...prevState,
            dialogWaringOpened: true
        }));
    }

    closeDialog() {
        this.setState(prevState => ({
            ...prevState,
            dialogWaringOpened: false
        }));
    }

  render() {
    const { classes } = this.props;

    if (!this.state.isAdmin && !this.state.isAdminSet) {

        return (
            <div />
        )
    } else if (!this.state.isAdmin && this.state.isAdminSet) {

        return (
            <Redirect
            to={{
              pathname: "/login"
            }}
        />
        )
    }

    return (
    <>
      <Authenticated />
      <MainAppBar
        headerText="Zone"
        showHomeButton={true}
        showAdminButton={this.state.isAdmin}
      />
      <Container className={classes.formContainer} maxWidth="md">
      <form className={classes.form} autoComplete="off" onSubmit={this.submitForm}>
        <TextField
          required
          name="firstName"
          id="filled-required"
          label="Prénom"
          variant="filled"
          onChange={this.updateForm}
          className={classes.textField}
          inputProps={{ maxLength: 25 }}
        />
        <TextField
          required
          name="lastName"
          id="filled-required"
          label="Nom"
          variant="filled"
          onChange={this.updateForm}
          className={classes.textField}
          inputProps={{ maxLength: 25 }}
        />
        <br />
        <TextField
          required
          name="phoneNumber"
          id="filled-required"
          label="Numéro de téléphone"
          variant="filled"
          type="tel"
          onChange={this.updateForm}
          className={classes.textField}
          inputProps={{ maxLength: 25 }}
        />
        <TextField
          required
          name="email"
          id="filled-required"
          label="Email"
          type="email"
          variant="filled"
          onChange={this.updateForm}
          className={classes.textField}
          inputProps={{ maxLength: 40 }}
        />
        <br/><br/><Divider/><br/>
        <TextField
          required
          name="company"
          id="filled-required"
          label="Entreprise"
          variant="filled"
          onChange={this.updateForm}
          className={classes.textField}
          inputProps={{ maxLength: 35 }}
        />
        <TextField
          required
          name="siret"
          id="filled-required"
          label="Numéro Siret"
          variant="filled"
          type="number"
          onChange={this.updateForm}
          className={classes.textField}
          inputProps={{ maxLength: 25 }}
        />
        <br />
        <TextField
          required
          name="streetAddress"
          id="filled-required"
          label="Rue"
          variant="filled"
          onChange={this.updateForm}
          className={classes.textField}
          inputProps={{ maxLength: 25 }}
        />
        <TextField
          required
          name="city"
          id="filled-required"
          label="Ville"
          variant="filled"
          onChange={this.updateForm}
          className={classes.textField}
          inputProps={{ maxLength: 25 }}
        />
        <TextField
          required
          name="zipCode"
          id="filled-required"
          label="Code postal"
          variant="filled"
          type="number"
          onChange={this.updateForm}
          className={classes.textField}
          inputProps={{ maxLength: 25 }}
        />
        <br/><br/><Divider/><br/>

        <TextField
          required
          name="maxNbOfTours"
          id="outlined-number"
          label="Nombre maximum de parcours autorisés"
          variant="filled"
          onChange={this.updateForm}
          className={classes.textField}
          type="number"
          InputProps={{
              inputProps: { 
                  max: 100, min: 0 
              }
          }}
        />
        <InputLabel id="select-role">Type de compte *</InputLabel>
        <Select
          name="role"
          labelId="select-response-type"
          id="select-response-type"
          value={this.state.account.role}
          onChange={this.updateForm}
        >
          <MenuItem value={"EDITOR"}>Éditeur</MenuItem>
          <MenuItem value={"ADMIN"} className={classes.danger}>Administrateur</MenuItem>
        </Select>

        <DialogWarning
          open={this.state.dialogWaringOpened}
          primaryText="Voulez vous vraiment donner à cette utilisateur le role d'administrateur"
          secondaryText="Cette utilisateur aura la possibilité d'éditer et de supprimer tout les comptes présents."
          primaryAction={this.signup}
          closeAction={this.closeDialog}
          primaryButtonText="Valider"
          secondaryButtonText="Annuler"
        />

      <br/><br/><Divider/><br/>

      { this.state.showError ? (
        <Alert severity="error">{this.state.errorMessage}</Alert>
      ): (<div />) }
      { this.state.showSuccess ? (
        <Alert severity="success">{this.state.successMessage}</Alert>
      ): (<div />) }
      <br/>

      
      <Button variant="outlined" color="primary" type="submit">
        ENREGISTRER
      </Button>
      </form>
      </Container>
      </>
    )
  }

}

export default withStyles(styles, { withTheme: true })(SignupUser);