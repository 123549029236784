import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { Redirect } from "react-router-dom";
import AccountIcon from '@material-ui/icons/AccountCircle';
import List from '@material-ui/core/List';
import DoneIcon from '@material-ui/icons/Done';
import MapIcon from '@material-ui/icons/Map';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Divider, Container, Button, InputLabel, IconButton, Typography, Select, MenuItem } from "@material-ui/core";
import qs from "qs";

import ApiTour from "../../API/ApiTour";
import ApiAdmin from "../../API/ApiAdmin";
import ApiUser from "../../API/ApiUser";
import DraggableList from "../components/draggableList";
import ListItemLink from '../components/listItemLink';
import DialogWarning from '../components/dialogWarning';
import Authenticated from '../components/authenticated';
import AuthStorage from "../../utils/authStorage";
import MainAppBar from "../components/MainAppBar";
import { validateAccount } from "../../utils/validateAccount";
import { BarChart } from "../components/BarChart"
import BarChartUtils from "../../utils/barChart"

import { apiHost } from '../../constants/ApiConstants';


const styles = (theme) => ({
    root: {
        flexGrow: 1,
      },
      appBar: {
        backgroundColor: theme.palette.darkGrey.main
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
      formContainer: {
        paddingTop: '30px',
        paddingBottom: '30px'
      },
      form: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '20ch'
        }
      },
      extendedIcon: {
        marginRight: theme.spacing(1),
      },
      link: {
        textDecoration: 'none'
      },
      textField: {
        minWidth: 200
      },
      textfieldLong: {
        minWidth: 400
      },
      list: {
        backgroundColor: theme.palette.lightGrey.main,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        borderRadius: 5,
        listStyle: 'none'
      },
      buttonListPlus: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 5,
        color: 'white'
      },
      returnButtonLink : {
        textDecoration: 'none',
        margin: theme.spacing(2)
      },
      danger: {
        color: 'red'
      }
});

class ManageAccount extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hoverChart: null,
      selection: [{ series: '', point: 0 }],
      tooltipTarget: null,
      tooltipEnabled: true,
      userId: -1,
      toursLoaded: false,
      analyticsLoaded: false,
      isAdmin: false,
      isAdminSet: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      accountLoaded: false,
      account:
        {
          firstName: "",
          lastName: "",
          company: "",
          email: "",
          phoneNumber: "",
          createdAt: "",
          streetAddress: "",
          city: "",
          zipCode: -1,
          siret: "",
          maxNbOfTours: -1
        },
      tours: [
        {
          name: "",
          createdAt: "",
          isValid: false,
          orderId: 0,
          id: 0
        }
      ],
      secretCode: "",
      analyticsFromApi: null,
      analytics: { datasets: [] },
      monthOffset: 999,
      tourNames: []
    }


    this.submitForm = this.submitForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.updateMonthOffset = this.updateMonthOffset.bind(this);

    this.changeHover = hoverChart => this.setState({ hoverChart });

    this.click = ({ targets }) => {
      const target = targets[0];
      if (target) {
        this.setState(({ selection }) => ({
          selection: selection[0] && compareTargets(selection[0], target) ? [] : [target],
        }));
      }
    };
  }

  componentDidMount() {
    document.title = "Compte client | Landing Zone"

    let paramUserId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id;
    if (!paramUserId) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Une erreur est survenue. Impossible de trouver les données liées à cette utilisateur",
          showSuccess: false,
        }));

        return;
    }

    this.setState( prevState => ({
        ...prevState,
        userId: paramUserId
    }))

    this.toggleTooltip = () => this.setState(({ tooltipEnabled }) => ({
      tooltipEnabled: !tooltipEnabled,
      tooltipTarget: null,
    }));

    this.setStateIsAdmin()
    this.getAccount(paramUserId);
    this.getTours(paramUserId);
    this.getAnalytics(paramUserId)
  }

  async updateMonthOffset(event) {
    let target = event.target;
    let value = target.value;

    let analyticsDatasFormatted = await BarChartUtils.formatDatasChart(this.state.analyticsFromApi, this.state.tourNames, value)

    this.setState(prevState => ({
        ...prevState,
        monthOffset: value,
        analytics: analyticsDatasFormatted
    }))
  }

  async getAnalytics(userId) {
    let analytics = await ApiAdmin.getAccountAnalytics(userId);

    if (analytics.status != 200 || !analytics.data) {
      return
    }

    let analyticsDatas = [];
    let tourNames = [];


    for (var i in analytics.data) {
      console.log("Sending tour id ", analytics.data[i].TourId)
      let tour = await ApiTour.getTour(analytics.data[i].TourId);
      let tourName = ""
      if (tour.status == 200 && tour.data) {
        tourName = tour.data.title
      }
      if (!tourNames.includes(tourName) && tourName != "") {
        tourNames.push(tourName);
      }

      analyticsDatas.push({
        tourName: tourName,
        id: analytics.data[i].id,
        createdAt: analytics.data[i].created_at,
        score: analytics.data[i].score,
        distanceTraveled: analytics.data[i].distanceTraveled
      });
    };

    let analyticsDatasFormatted = await BarChartUtils.formatDatasChart(analyticsDatas, tourNames, null)

    this.setState(prevState => ({
      ...prevState,
      analyticsLoaded: true,
      analytics: analyticsDatasFormatted,
      analyticsFromApi: analyticsDatas,
      tourNames: tourNames
    }));
  }

  async getTours(userId) {
    ApiAdmin.getToursClient(userId).then((tours) => {
      var dateOptions = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "numeric"
      };

      let toursDatas = [];

      if (!tours.data)
        return

      tours.data.forEach((elem, i) => {
        toursDatas.push({
          name: elem.title,
          createdAt: new Date(elem.createdAt).toLocaleDateString("fr", dateOptions),
          id: elem.id,
          isValid: elem.isValid
        });
      });


      this.setState(prevState => ({
        ...prevState,
        toursLoaded: true,
        tours: toursDatas
      }));

    });
  }

  async getAccount(userId) {

    ApiAdmin.getUserAccount(userId).then((response) => {
        if (response.status != 200 || !response.data) {
            this.setState(prevState => ({
                ...prevState,
                showError: true,
                showSuccess: false,
                errorMessage: "Impossible de récupérer de données sur cette utilisateur"
            }))

            return
        }
        let address = response.data.address.split(';');
        if (!address || address.length != 3) {
            address.push('')
            address.push('')
        }

        this.setState(prevState => ({
            ...prevState,
            accountLoaded: true,
            account: {
                firstName: response.data.firstname,
                lastName: response.data.lastname,
                company: response.data.companyName,
                phoneNumber: response.data.phoneNumber,
                createdAt: response.data.createdAt,
                streetAddress: address[0],
                city: address[1],
                zipCode: Number(address[2]) | 0,
                siret: response.data.companySiret,
                email: response.data.email,
                maxNbOfTours: response.data.maxTours,
                secretCode: response.data.secretCodeIPad
            }
        }))

        console.log("Data resp: ", response.data);
        console.log("state account: ", this.state.account)

    });
  }

  async submitForm(event) {
    event.preventDefault();

    this.updateProfile();
  }

  async updateProfile() {
    console.log("new state : ", this.state)
    if (!this.state.account.firstName || this.state.account.firstName == "" ||
        !this.state.account.lastName || this.state.account.lastName == "" ||
        !this.state.account.company || this.state.account.company == "" ||
        !this.state.account.siret || this.state.account.siret == "" ||
        !this.state.account.phoneNumber || this.state.account.phoneNumber == "" ||
        !this.state.account.streetAddress || this.state.account.streetAddress == "" ||
        !this.state.account.city || this.state.account.city == "" ||
        this.state.account.zipCode == -1) {
            this.setState(prevState => ({
                ...prevState,
                showError: true,
                errorMessage: "Veuillez remplire tout les champs",
                showSuccess: false,
                dialogWaringOpened: false,
            }));

            return;
    }

    console.log("state: ", this.state)

    try {
        validateAccount(
          "parameternotsent@gmail.com",
          this.state.account.siret,
          this.state.account.phoneNumber,
          this.state.account.maxNbOfTours
         )
    } catch (err) {
        console.log(err.message)
        this.setState(prevState => ({
            ...prevState,
            showError: true,
            errorMessage: err.message,
            showSuccess: false,
            dialogWaringOpened: false,
        }));

        return
    }

    let addr = this.state.account.streetAddress + ";" + this.state.account.city + ";" + this.state.account.zipCode
    let response = await ApiAdmin.updateUserAccount(
        this.state.userId,
        this.state.account.firstName,
        this.state.account.lastName,
        this.state.account.company,
        addr,
        this.state.account.siret,
        this.state.account.phoneNumber,
        this.state.account.maxNbOfTours
    )

    if (response.status == 200 && response.data) {
        this.setState(prevState => ({
            ...prevState,
            showError: false,
            showSuccess: true,
            successMessage: "Le compte à bien été mis à jour",
            dialogWaringOpened: false,
        }));
    } else {
        this.setState(prevState => ({
            ...prevState,
            showError: true,
            errorMessage: "Une érreur est survenu",
            showSuccess: false,
            dialogWaringOpened: false,
        }));
    }
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin()

    if (response.status == 200) {
      this.setState(prevState => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
        isAdminSet: true
      }));  
    }
  }

  updateForm(event) {
    let target = event.target;
    let value = target.value;

    if (target.name == "zipCode") {
      value = Number(target.value);
    }

    this.setState(prevState => ({
        ...prevState,
        account: {
            ...prevState.account,
            [target.name]: value
        }
    }))
  }

  render() {
    const { classes } = this.props;

    if (!this.state.isAdmin && !this.state.isAdminSet) {

        return (
            <div ></div>
        )
    } else if (!this.state.isAdmin && this.state.isAdminSet) {

        return (
            <Redirect
            to={{
              pathname: "/"
            }}
        />
        )
    }

    let toursItems = [];
    if (this.state.tours) {
      this.state.tours.forEach((tour) => {
        let item = {
          idInt: tour.id,
          id: `tour-${tour.id}`,
          primary: tour.name,
          secondary: tour.createdAt,
          to: `/new-tour?id=${tour.id}`,
          icon: <MapIcon/>,
          isValid: tour.isValid
        }
  
        toursItems.push(item);
      });
    }
    
    return (
      <>
        <Authenticated />
        <MainAppBar headerText="Compte client" showHomeButton={true} showAdminButton={this.state.isAdmin}/>
        <Container className={classes.formContainer} maxWidth="md">
          <form className={classes.form} autoComplete="off" onSubmit={this.submitForm}>
            <TextField
            required
            name="firstName"
            id="filled-required"
            defaultValue={this.state.account.firstName}
            label="Prénom"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'firstNameNotLoaded' : 'firstNameLoaded'}
            />
            <TextField
            required
            name="lastName"
            id="filled-required"
            defaultValue={this.state.account.lastName}
            label="Nom"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'lastNameNotLoaded' : 'lastNameLoaded'}
            />
            <br />
            <TextField
            disabled
            id="outlined-disabled"
            defaultValue={this.state.account.email}
            label="Email"
            variant="filled"
            className={classes.textField}
            key={this.state.accountLoaded ? 'emailNotLoaded' : 'emailLoaded'}
            />
            <TextField
            required
            name="phoneNumber"
            id="filled-required"
            defaultValue={this.state.account.phoneNumber}
            label="Numéro de téléphone"
            variant="filled"
            type="tel"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'phoneNumberNotLoaded' : 'phoneNumberLoaded'}
            />
            <br/><br/><Divider/><br/>
            <TextField
            required
            name="company"
            id="filled-required"
            defaultValue={this.state.account.company}
            label="Entreprise"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 35 }}
            key={this.state.accountLoaded ? 'companyNotLoaded' : 'companyNameLoaded'}
            />
            <TextField
            required
            name="siret"
            id="filled-required"
            defaultValue={this.state.account.siret}
            label="Numéro Siret"
            variant="filled"
            type="number"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'siretNameNotLoaded' : 'siretNameLoaded'}
            />
            <br />
            <TextField
            required
            name="streetAddress"
            id="filled-required"
            defaultValue={this.state.account.streetAddress}
            label="Rue"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'streetAddressNotLoaded' : 'streetAddressNameLoaded'}
            />
            <TextField
            required
            name="city"
            id="filled-required"
            defaultValue={this.state.account.city}
            label="Ville"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'cityNameNotLoaded' : 'cityNameLoaded'}
            />
            <TextField
            required
            name="zipCode"
            id="filled-required"
            defaultValue={this.state.account.zipCode}
            label="Code postal"
            variant="filled"
            type="number"
            onChange={this.updateForm}
            className={classes.textField}
            inputProps={{ maxLength: 25 }}
            key={this.state.accountLoaded ? 'zipCodeNotLoaded' : 'zipCodeLoaded'}
            />

            <br/><br/><Divider/><br/>
            <TextField
            required
            name="maxNbOfTours"
            id="filled-required"
            defaultValue={this.state.account.maxNbOfTours}
            label="Nombre max de parcours"
            type="number"
            variant="filled"
            onChange={this.updateForm}
            className={classes.textField}
            key={this.state.accountLoaded ? 'nbMaxToursNotLoaded' : 'nbMaxToursLoaded'}
            />
            <TextField
            disabled
            id="outlined-disabled"
            defaultValue={this.state.account.secretCode}
            label="Code secret"
            helperText="Il s'agit du code que l'utilisateur doit rentrer dans ses IPads pour pouvoir récupérer ses parcours"
            variant="filled"
            className={classes.textField}
            key={this.state.accountLoaded ? 'secretCodeNotLoaded' : 'secretCodeLoaded'}
            />
            <br/><br/><Divider/><br/>
            <Typography variant="h6" gutterBottom>
              Parcours de l'utilisateur
            </Typography>
            <div className={classes.list}>
              { this.state.toursLoaded && toursItems && toursItems.length > 0 ? (
                  <DraggableList items={toursItems} onDragEnd={this.onDragEnd}/>
                ) : (
                  <div style={{ textAlign: "center" }}>Aucun parcours</div>
                )
              }
            </div>
            <br/><br/><Divider/><br/>

            <Select
              labelId="select-month-offset"
              id="select-month-offset"
              value={this.state.monthOffset ?? 999}
              onChange={this.updateMonthOffset}
            >
              <MenuItem value={999}>Tout</MenuItem>
              <MenuItem value={3}>3 mois</MenuItem>
              <MenuItem value={6}>6 mois</MenuItem>
              <MenuItem value={12}>1 an</MenuItem>
            </Select>

            <BarChart chartData={this.state.analytics} />

            <br/><br/><Divider /><br/>
            { this.state.showError ? (
                <Alert severity="error">{this.state.errorMessage}</Alert>
                ): (<div />) }
            { this.state.showSuccess ? (
                <Alert severity="success">{this.state.successMessage}</Alert>
                ): (<div />) }
            <br/>
            <Button variant="outlined" color="primary" type="submit">
                ENREGISTRER
            </Button>
          </form>
        </Container>
      </>
    )
  }

}

export default withStyles(styles, { withTheme: true })(ManageAccount);