import React from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, CardMedia, Card, Typography, Button, Slider, Divider, IconButton, Box, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import qs from 'qs';
import HomeIcon from '@material-ui/icons/Home';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import QrIcon from '@material-ui/icons/CropFree'

import ApiUser from '../../API/ApiUser';
import ApiTour from '../../API/ApiTour';
import ApiZone from '../../API/ApiZone';
import ImageUtils from '../../utils/imageUtils';
import DraggableList from "../components/draggableList";
import ListItemLink from '../components/listItemLink';
import DialogWarning from '../components/dialogWarning'
import LoadingView from '../components/loadingView';
import Authenticated from '../components/authenticated';
import MainAppBar from "../components/MainAppBar";
import { getSizeInMb } from "../../utils/sizeInMb";
import { GameMode } from "../../constants/TourConstants";
import { ZoneMode } from "../../constants/TourConstants";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  loadingView: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
  },
  title: {
    flexGrow: 1,
  },
  formContainer: {
    paddingTop: '30px',
    paddingBottom: '30px'
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '20ch'
    }
  },
  imageCardInGameBackground: {
    marginLeft: 10,
    width: 1152 / 4,
    height: 1207 / 4,
    marginTop: 20,
  },
  imageCardBackground: {
    marginLeft: 10,
    width: 1152 / 4,
    height: 1207 / 4,
    marginTop: 20,
  },
  imageCardAnnotation: {
    marginLeft: 10,
    width: 75,
    height: 75,
    marginTop: 20,
  },
  imageCardMedia: {
    width: "100%",
    height: "100%"
  },
  uploadFile: {
    marginTop: 10,
    marginLeft: 10
  },
  link: {
    textDecoration: 'none'
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderRadius: 5,
    listStyle: 'none'
  },
  buttonListPlus: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    color: 'white'
  },
  imageDimensionsText: {
    paddingLeft: 8,
    color: "#494949ff",
    fontSize: 12
  }
});

class NewTour extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      showBackgroundImageTooBig: false,
      showAnnotationImageTooBig: false,
      showInGameBackgroundImageTooBig: false,
      tourLoaded: false,
      editing: false,
      zonesOrderIdsChanged: false,
      dialogWaringOpened: false,
      loadingViewOpened: false,
      zoneIdToDelete: -1,
      zonesDatas : [
          {
            name: "",
            id: 9999,
            orderId: 0,
            isValid: false,
            zoneMode: ZoneMode
          }
      ],
      formDatas: {
        tourId: null,
        title: "",
        gameMode: GameMode.MAP_AND_QRCODE,
        homeAnnotationName: "",
        backgroundImageFile: null,
        annotationImageFile: null,
        inGameBackgroundImageFile: null,
        backgroundImageUrl: null,
        annotationImageUrl: null,
        inGameBackgroundImageUrl: null,
        homeName: "",
        timer: 60,
        homeCoords: {
          longitude: 1,
          latitude: 1
        },
        cameraBoundsNE: {
          longitude: 0,
          latitude: 0
        },
        cameraBoundsSW: {
            longitude: 0,
            latitude: 0
        }
      }
    }
    this.backgroundImageSelected = this.backgroundImageSelected.bind(this);
    this.inGameBackgroundImageSelected = this.inGameBackgroundImageSelected.bind(this);
    this.annotationImageSelected = this.annotationImageSelected.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.updateTitle = this.updateTitle.bind(this);
    this.updateHomeAnnotationName = this.updateHomeAnnotationName.bind(this);
    this.updateHomeName = this.updateHomeName.bind(this);
    this.updateTimer = this.updateTimer.bind(this);
    this.updateHomeCoordsLongitude = this.updateHomeCoordsLongitude.bind(this);
    this.updateHomeCoordsLatitude = this.updateHomeCoordsLatitude.bind(this);
    this.updateCameraBoundsNELongitude = this.updateCameraBoundsNELongitude.bind(this);
    this.updateCameraBoundsNELatitude = this.updateCameraBoundsNELatitude.bind(this);
    this.updateCameraBoundsSWLongitude = this.updateCameraBoundsSWLongitude.bind(this);
    this.updateCameraBoundsSWLatitude = this.updateCameraBoundsSWLatitude.bind(this);
    this.handleGameModeChange = this.handleGameModeChange.bind(this);

    this.onDragEnd = this.onDragEnd.bind(this);
    this.deleteZone = this.deleteZone.bind(this);
    this.openDeleteZoneDialog = this.openDeleteZoneDialog.bind(this);
    this.closeDeleteZoneDialog = this.closeDeleteZoneDialog.bind(this);
  }

  componentDidMount() {
    let id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id;

    id = parseInt(id);
    
    this.setStateIsAdmin()

    if (!id)
      return;

    this.setState(prevState => ({
      ...prevState,
      formDatas: {
        ...prevState.formDatas,
        tourId: id
      }
    }))

    ApiTour.getTour(id).then((tourDatas) => {
      if (!tourDatas || tourDatas.status !== 200) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Une erreur est survenue lors de la récupération du circuit",
          showSuccess: false,
          successMessage: "",  
        }));
  
        return;
      }
      this.setState(prevState => ({
        ...prevState,
        editing: true
      }))

      this.setFormDatas(tourDatas.data);
    });

    ApiZone.getZones(id).then((zones) => {
      let zonesDatas = [];

      if (zones.status !== 200 && zones.status !== 204) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Une erreur est survenue lors de la récupération des zones",
          showSuccess: false,
          successMessage: "",  
        }));
  
        return;
      }


      if (zones.status == 204)
        return;

      zones.data.forEach((elem, i) => {
        zonesDatas.push({
          name: elem.name,
          id: elem.id,
          isValid: elem.isValid,
          orderId: elem.orderId,
          zoneMode: elem.zoneMode
        });
      });

      this.setState(prevState => ({
        ...prevState,
        zonesDatas: zonesDatas,
        zonesLoaded: true
      }));
    });
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin()

    if (response.status == 200) {
      this.setState(prevState => ({
        ...prevState,
        isAdmin: response.data.isAdmin
      }));  
    }
  }

  setFormDatas(tour) {
    this.setState( prevState => ({
      ...prevState,
      tourLoaded: true,
      formDatas: {
        ...prevState.formDatas,
        tourId: tour.id,
        title: tour.title,
        homeAnnotationName: tour.homeAnnotationName,
        backgroundImageUrl: tour.backgroundImageUrl,
        annotationImageUrl: tour.annotationImageUrl,
        inGameBackgroundImageUrl: tour.inGameBackgroundImageUrl,
        gameMode: tour.gameMode,
        homeName: tour.homeName,
        timer: tour.timer,
        homeCoords: {
          longitude: tour.homeCoordsLongitude,
          latitude: tour.homeCoordsLatitude
        },
        cameraBoundsNE: {
          longitude: tour.cameraBoundsNELongitude,
          latitude: tour.cameraBoundsNELatitude
        },
        cameraBoundsSW: {
            longitude: tour.cameraBoundsSWLongitude,
            latitude: tour.cameraBoundsSWLatitude
        }        
      }
    }));
  }

  handleGameModeChange(event, value) {
    event.preventDefault();

    console.log("Mode jeux: ", event.target.value)

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        gameMode: event.target.value
      }
    }));
  }

  updateTitle(event) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        title: event.target.value
      }
    }));
  }
  updateHomeAnnotationName(event) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        homeAnnotationName: event.target.value
      }
    }));
  }
  
  updateHomeName(event) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        homeName: event.target.value
      }
    }));
  }
  
  updateTimer(event, value) {
    event.preventDefault();

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        timer: parseInt(value)
      }
    }));
  }

  updateHomeCoordsLongitude(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value)))
      return;

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        homeCoords: {
          ...prevState.formDatas.homeCoords,
          longitude: parseFloat(event.target.value)
        }
      }
    }));
  }
  
  updateHomeCoordsLatitude(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value)))
      return;

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        homeCoords: {
          ...prevState.formDatas.homeCoords,
          latitude: parseFloat(event.target.value)
        }
      }
    }));
  }
  
  updateCameraBoundsNELongitude(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value))) {
      return;
    }

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        cameraBoundsNE: {
          ...prevState.formDatas.cameraBoundsNE,
          longitude: parseFloat(event.target.value)
        }
      }
    }));
  }
  
  updateCameraBoundsNELatitude(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value))) {
      return;
    }

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        cameraBoundsNE: {
          ...prevState.formDatas.cameraBoundsNE,
          latitude: parseFloat(event.target.value)
        }
      }
    }));  }
  
  updateCameraBoundsSWLongitude(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value))) {
      return;
    }

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        cameraBoundsSW: {
          ...prevState.formDatas.cameraBoundsSW,
          longitude: parseFloat(event.target.value)
        }
      }
    }));
  }
  
  updateCameraBoundsSWLatitude(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value))) {
      return;
    }

    this.setState( prevState => ({
      formDatas: {
        ...prevState.formDatas,
        cameraBoundsSW: {
          ...prevState.formDatas.cameraBoundsSW,
          latitude: parseFloat(event.target.value)
        }
      }
    }));
  }

  inGameBackgroundImageSelected(event) {
    event.preventDefault();

    let fileSizeMb = getSizeInMb(event.target.files[0].size)

    if (fileSizeMb > 1) {
      this.setState( prevState => ({
          ...prevState,
          showInGameBackgroundImageTooBig: true
        }));

      return;
    }

    this.setState( prevState => ({
      ...prevState,
      showInGameBackgroundImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        inGameBackgroundImageFile: event.target.files[0]
      }
    }));
  }

  backgroundImageSelected(event) {
    event.preventDefault();

    let fileSizeMb = getSizeInMb(event.target.files[0].size)

    if (fileSizeMb > 1) {
      this.setState( prevState => ({
          ...prevState,
          showBackgroundImageTooBig: true
        }));

      return;
    }

    this.setState( prevState => ({
      ...prevState,
      showBackgroundImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        backgroundImageFile: event.target.files[0]
      }
    }));
  }

  annotationImageSelected(event) {
    event.preventDefault();

    let fileSizeMb = getSizeInMb(event.target.files[0].size)

    if (fileSizeMb > 0.2) {
      this.setState( prevState => ({
          ...prevState,
          showAnnotationImageTooBig: true
        }));

      return;
    }

    this.setState( prevState => ({
      ...prevState,
      showAnnotationImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        showAnnotationImageTooBig: false,
        annotationImageFile: event.target.files[0]
      }
    }));
  }

  async submitForm(event) {
    event.preventDefault();

    var serverResp = null;

    if (this.state.editing) {
      this.setState(prevState => ({
        ...prevState,
        loadingViewOpened: true
      }));
      if (this.state.formDatas.gameMode == GameMode.ONLY_QRCODE && (this.state.formDatas.inGameBackgroundImageUrl == "" && !this.state.formDatas.inGameBackgroundImageFile)) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          loadingViewOpened: false,
          errorMessage: "Vous devez séléctionner l'image d'arrière plan visible en jeux",
          showSuccess: false,
          successMessage: "",
        }));
  
        return;
      } else if (this.state.formDatas.gameMode == GameMode.MAP_AND_QRCODE && (this.state.formDatas.annotationImageUrl == "" && !this.state.formDatas.annotationImageFile)) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          loadingViewOpened: false,
          errorMessage: "Vous devez séléctionner l'image d'annotation de départ",
          showSuccess: false,
          successMessage: "",
        }));
  
        return;
      }
      serverResp = await ApiTour.updateTour(this.state.formDatas, this.state.formDatas.tourId);
      if (this.state.zonesOrderIdsChanged) {
        let res = await ApiZone.updateZonesOrdersIds(this.state.zonesDatas);
      }
    } else {
      if (this.state.formDatas.gameMode == GameMode.ONLY_QRCODE && this.state.formDatas.inGameBackgroundImageFile == null) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Vous devez séléctionner l'image d'arrière plan visible en jeux",
          showSuccess: false,
          successMessage: "",
        }));
  
        return;
      }
      if (this.state.formDatas.backgroundImageFile == null) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Vous devez séléctionner l'image d'arrière plan",
          showSuccess: false,
          successMessage: "",
        }));
  
        return;
      }

      if (this.state.formDatas.annotationImageFile == null && this.state.formDatas.gameMode == GameMode.MAP_AND_QRCODE) {
        this.setState(prevState => ({
          ...prevState,
          showError: true,
          errorMessage: "Vous devez séléctionner l'image de l'annotation de départ",
          showSuccess: false,
          successMessage: "",
        }));
  
        return;
      }
  
      this.setState(prevState => ({
        ...prevState,
        loadingViewOpened: true
      }));
      serverResp = await ApiTour.uploadTour(this.state.formDatas);
    }

    if (serverResp.status == 403) {
      this.setState(prevState => ({
        ...prevState,

        showSuccess: false,
        showError: true,
        errorMessage: "Vous avez atteint le nombre maximum de parcours autorisé.",
        dialogWaringOpened: false,
        loadingViewOpened: false
      }));
    } else if (serverResp.status != 201 && serverResp.status != 200) {
      this.setState(prevState => ({
        ...prevState,
        showError: true,
        errorMessage: `Une érreur est survenue lors de la communication avec le serveur`,
        showSuccess: false,
        successMessage: "",
        loadingViewOpened: false
      }));
    } else {
        this.setState(prevState => ({
        ...prevState,
        showSuccess: true,
        editing: true,
        successMessage: "Le circuit à bien été sauvegardé",
        showError: false,
        errorMessage: "",
        loadingViewOpened: false,
        formDatas: {
          ...prevState.formDatas,
          tourId: serverResp.data.tourId
        }
      }));
    }
  }

  onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const zonesDatas = this.state.zonesDatas;

    let zoneSource = zonesDatas.find((zone, i) => {
      return i === source.index
    });
    let zoneDest = zonesDatas.find((zone, i) => {
      return i === destination.index
    });

    zonesDatas.splice(destination.index, 0, zonesDatas.splice(source.index, 1)[0]);

    zonesDatas.forEach((zone, i) => {
      zone.orderId = i
      console.log("Zone name: ", zone , " at index : ", zone.orderId);
    });

    this.setState(prevState => ({
      ...prevState,
      zonesOrderIdsChanged: true,
      zonesDatas: zonesDatas
    }));
  };

  async deleteZone(zoneId) {
    let res = await ApiZone.deleteZone(zoneId);

    if (res.status == 200) {
      let newZones = this.state.zonesDatas;
      newZones.forEach( (zone, index) => {
        if (zone.id == zoneId) {
          newZones.splice(index, 1);
        }
      });
      this.setState(prevState => ({
        ...prevState,
        showSuccess: true,
        successMessage: "La zone à bien été supprimé",
        showError: false,
        dialogWaringOpened: false,
        zonesDatas: newZones
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        showError: true,
        errorMessage: "Une érreur est survenue durant la suppréssion de la zone",
        showSuccess: false,
        successMessage: "",
        dialogWaringOpened: false,
      }));
    }
  }

  openDeleteZoneDialog(zoneId) {

    this.setState(prevState => ({
      ...prevState,
      zoneIdToDelete: zoneId,
      dialogWaringOpened: true
    }));
  }

  closeDeleteZoneDialog() {
    this.setState(prevState => ({
      ...prevState,
      dialogWaringOpened: false
    }));
  }

  render() {
    const { classes } = this.props;

    let zonesItems = [];

    if (this.state.zonesDatas) {
      this.state.zonesDatas.forEach((zone) => {
        let icon = <LocationOnIcon/>;
        if (zone.zoneMode == ZoneMode.QRCODE) {
          icon = <QrIcon/>;
        }
        let item = {
          id: `zone-${zone.id}`,
          idInt: zone.id,
          primary: zone.name,
          secondary: "",
          to: `/new-zone?tourId=${this.state.formDatas.tourId}&zoneId=${zone.id}`,
          icon: icon,
          isValid: zone.isValid
        }
  
        zonesItems.push(item);
      });
    }

    return (
    <>
      <Authenticated />
      <MainAppBar headerText="Parcours" showHomeButton={true} showAdminButton={this.state.isAdmin}/>
      <Container className={classes.formContainer} maxWidth="md">
      <form className={classes.form} autoComplete="off" onSubmit={this.submitForm}>
        <TextField
            required
            id="filled-required"
            label="Titre du circuit"
            defaultValue={this.state.formDatas.title}
            onChange={this.updateTitle}
            variant="filled"
            key={this.state.tourLoaded ? 'titleNotLoaded' : 'titleLoaded'}
          />
        <br/><br/><Divider/><br/>

        <Button
          variant="contained"
          error={true}
          color="secondary"
          className={classes.uploadFile}
          startIcon={<CloudUploadIcon />}
          component="label"
        >
            Image d'arrière plan
          <input
            type="file"
            name="file1"
            onChange={this.backgroundImageSelected}
            hidden
            accept=".png,.jpg,.jpeg"
          />
        </Button>
        <Typography className={classes.imageDimensionsText} id="discrete-slider" gutterBottom>
          Taille maximum: 1mo
        </Typography>
        <Typography className={classes.imageDimensionsText} id="discrete-slider" gutterBottom>
          Dimensions recommandées: 1152x1207px
        </Typography>
        { this.state.showBackgroundImageTooBig ? (
          <Typography className={classes.imageDimensionsText} style={{ color: 'red' }} gutterBottom>
            L'image ne doit pas éxcéder 1mo
          </Typography>
        ): (<div />) }

        <Card className={classes.imageCardBackground} >
          <CardMedia
            className={classes.imageCardMedia}
            image=
            {
              ImageUtils.getImageUrlFromFile(this.state.formDatas.backgroundImageFile)
              ? ImageUtils.getImageUrlFromFile(this.state.formDatas.backgroundImageFile)
              : ImageUtils.getImageUrlFromUri(this.state.formDatas.backgroundImageUrl)
            }
            title="BackgroundImage"
          />
        </Card>

        <br/><br/><Divider/><br/>

        <FormControl className={classes.formControl}>
          <InputLabel id="select-response-type">Type de parcours</InputLabel>
            <Select
              labelId="select-response-type"
              id="select-response-type"
              value={this.state.formDatas.gameMode}
              onChange={this.handleGameModeChange}
            >
              <MenuItem value={GameMode.MAP_AND_QRCODE}>Map et QRCodes</MenuItem>
              <MenuItem value={GameMode.ONLY_QRCODE}>Seulement QRCodes</MenuItem>
          </Select>
        </FormControl>

        { this.state.formDatas.gameMode == GameMode.ONLY_QRCODE ? (
          <>
          <br/><br/>
            <Button
            variant="contained"
            error={true}
            color="secondary"
            className={classes.uploadFile}
            startIcon={<CloudUploadIcon />}
            component="label"
          >
              Image d'arrière plan visible en jeux
            <input
              type="file"
              name="file2"
              onChange={this.inGameBackgroundImageSelected}
              hidden
              accept=".png,.jpg,.jpeg"
            />
          </Button>
          <Typography className={classes.imageDimensionsText} id="discrete-slider" gutterBottom>
            Taille maximum: 1mo
          </Typography>
          <Typography className={classes.imageDimensionsText} id="discrete-slider" gutterBottom>
            Dimensions recommandées: 1152x1207px
          </Typography>
          { this.state.showInGameBackgroundImageTooBig ? (
            <Typography className={classes.imageDimensionsText} style={{ color: 'red' }} gutterBottom>
              L'image ne doit pas éxcéder 1mo
            </Typography>
          ): (<div />) }

          <Card className={classes.imageCardInGameBackground}>
            <CardMedia
              className={classes.imageCardMedia}
              image=
              {
                ImageUtils.getImageUrlFromFile(this.state.formDatas.inGameBackgroundImageFile)
                ? ImageUtils.getImageUrlFromFile(this.state.formDatas.inGameBackgroundImageFile)
                : ImageUtils.getImageUrlFromUri(this.state.formDatas.inGameBackgroundImageUrl)
              }
              title="InGameBackgroundImage"
            />
          </Card>
        </>
        ) : (
          <>
          <br/><br/>
            <Button
              variant="contained"
              color="secondary"
              className={classes.uploadFile}
              startIcon={<CloudUploadIcon />}
              component="label"
            >
                Image de l'annotation de départ
              <input
                type="file"
                name="file3"
                onChange={this.annotationImageSelected}
                hidden
                accept=".png,.jpg,.jpeg"
              />
            </Button>
            <Typography className={classes.imageDimensionsText} align='inherit' gutterBottom>
              Taille maximum: 200ko
            </Typography>
            <Typography className={classes.imageDimensionsText} gutterBottom>
              Dimensions recommandées: 75x75px
            </Typography>
            { this.state.showAnnotationImageTooBig ? (
              <Typography className={classes.imageDimensionsText} style={{ color: 'red' }} gutterBottom>
              L'image ne doit pas éxcéder 200ko
              </Typography>
            ): (<div />) }
            <Card className={classes.imageCardAnnotation}>
              <CardMedia
                className={classes.imageCardMedia}
                image=
                {
                  ImageUtils.getImageUrlFromFile(this.state.formDatas.annotationImageFile)
                  ? ImageUtils.getImageUrlFromFile(this.state.formDatas.annotationImageFile)
                  : ImageUtils.getImageUrlFromUri(this.state.formDatas.annotationImageUrl)
                }
                title="AnnotationImage"
              />
            </Card>
            <br/>
            <TextField
              required
              id="filled-required"
              label="Longitude du départ"
              onChange={this.updateHomeCoordsLongitude}
              defaultValue={this.state.formDatas.homeCoords.longitude != 0 ? this.state.formDatas.homeCoords.longitude : ""}
              variant="filled"
              key={this.state.tourLoaded ? 'lonHomeCoordsNotLoaded' : 'lonHomeCoordsLoaded'}
            />
            <TextField
              required
              id="filled-required"
              label="Latitude du départ"
              onChange={this.updateHomeCoordsLatitude}
              defaultValue={this.state.formDatas.homeCoords.latitude != 0 ? this.state.formDatas.homeCoords.latitude : ""}
              variant="filled"
              inputProps={{ className: classes.input, pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?" }}
              key={this.state.tourLoaded ? 'latHomeCoordsNotLoaded' : 'latHomeCoordsLoaded'}
            />

            <br/><br/><Divider/><br/>
            <Typography className={classes.title} align='inherit' gutterBottom>
              Limites de map
            </Typography>
            <TextField
                required
                id="filled-required"
                label="Longitude Nord-Est"
                helperText="Ces coordonnées définissent les limites de la map afficher à l'utilisateur."
                defaultValue={this.state.formDatas.cameraBoundsNE.longitude != 0 ? this.state.formDatas.cameraBoundsNE.longitude : ""}
                onChange={this.updateCameraBoundsNELongitude}
                variant="filled"
                inputProps={{ className: classes.input, pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?" }}
                key={this.state.tourLoaded ? 'lonCamNELoaded' : 'lonCamNENotLoaded'}
            />
            <TextField
                required
                id="filled-required"
                label="Latitude Nord-Est"
                defaultValue={this.state.formDatas.cameraBoundsNE.latitude != 0 ? this.state.formDatas.cameraBoundsNE.latitude : ""}
                onChange={this.updateCameraBoundsNELatitude}
                variant="filled"
                inputProps={{ className: classes.input, pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?" }}
                key={this.state.tourLoaded ? 'latCamNELoaded' : 'latCamNENotLoaded'}
            />
            <TextField
                required
                id="filled-required"
                label="Longitude Sud-Ouest"
                defaultValue={this.state.formDatas.cameraBoundsSW.longitude != 0 ? this.state.formDatas.cameraBoundsSW.longitude : "" }
                onChange={this.updateCameraBoundsSWLongitude}
                variant="filled"
                inputProps={{ className: classes.input, pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?" }}
                key={this.state.tourLoaded ? 'lonCamSWLoaded' : 'lonCamSWNotLoaded'}
            />
            <TextField
                required
                id="filled-required"
                label="Latitude Sud-Ouest"
                defaultValue={this.state.formDatas.cameraBoundsSW.latitude != 0 ? this.state.formDatas.cameraBoundsSW.latitude : ""}
                onChange={this.updateCameraBoundsSWLatitude}
                variant="filled"
                inputProps={{ className: classes.input, pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?" }}
                key={this.state.tourLoaded ? 'latCamSWLoaded' : 'latCamSWNotLoaded'}
            />
          </>
        )}

        <br/><br/><Divider/><br/>

        <TextField
            required
            id="standard-helperText"
            label="Nom"
            defaultValue={this.state.formDatas.homeAnnotationName}
            onChange={this.updateHomeAnnotationName}
            helperText="Ce nom est affiché lorsque la partie est terminée"
            variant="filled"
            key={this.state.tourLoaded ? 'homeAnnottationNameLoaded' : 'homeAnnottationNameNotLoaded'}
        />

        <Typography id="discrete-slider" gutterBottom>
          Temps du circuit (minutes)
        </Typography>
          <Slider
            getAriaValueText={this.valueSliderTourTimer}
            onChange={this.updateTimer}
            value={this.state.formDatas.timer}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={5}
            marks
            min={30}
            max={120}
            key={this.state.tourLoaded ? 'timerTourNotLoaded' : 'timerTourLoaded'}
        />

      <br/><br/><Divider/><br/>

        <br/>

        <div className={classes.list}>

        { this.state.zonesLoaded ? (
          <DraggableList items={zonesItems} onDragEnd={this.onDragEnd} onDelete={this.openDeleteZoneDialog}/>
        ) : (
          <div></div>
        )
        }
        <Box className={classes.buttonListPlus}>
          <ListItemLink
            to={`/new-zone?tourId=${this.state.formDatas.tourId}`}
            key={1000} primary="NOUVELLE ZONE" icon={<AddCircleOutlineIcon/>}
            deleteButon={false}
            disabled={this.state.formDatas.tourId ? false : true}
          />
        </Box>
        </div>

        <DialogWarning
          open={this.state.dialogWaringOpened}
          primaryText="Voulez vous vraiment supprimer cette zone ?"
          secondaryText="Une fois supprimé il sera impossible de la récupérer."
          primaryAction={this.deleteZone}
          closeAction={this.closeDeleteZoneDialog}
          idItem={this.state.zoneIdToDelete}
          primaryButtonText="Supprimer"
          secondaryButtonText="Annuler"
        />
        <LoadingView
          className={classes.loadingView}
          open={this.state.loadingViewOpened}
        />


        <br/><br/><Divider/><br/>
        { this.state.showError ? (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        ): (<div />) }
        { this.state.showSuccess ? (
          <Alert severity="success">{this.state.successMessage}</Alert>
        ): (<div />) }
        <br/>
        
        <Button variant="outlined" color="primary" type="submit">
          ENREGISTRER
        </Button>
      </form>
      </Container>
      </>
    )
  }

}

export default withStyles(styles, { withTheme: true })(NewTour);